import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useStateValue } from "../../StateProvider/StateProvider";
import EditIcon from "../../assets/images/edit_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import LockIcon from "../../assets/images/lockIcon.svg";
import UnlockIcon from "../../assets/images/unlockIcon.svg";
import EnableIcon from "../../assets/images/enable.png";
import DisableIcon from "../../assets/images/disable.png";
import FluentDeleteIcon from "../../assets/images/fluentDelete_icon.svg";
import { Delete, Post, Get } from "../../_common/httpService";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "react-bootstrap/Accordion";
import { Modal } from "react-bootstrap";
import { updateRadioControllerItemName } from "../updateRadioControllerItemName";

const EditAndDelete = (props) => {
  const { id, prop, frame, defaultControl, activeFlag, controlId, frameName } = props;

  const [
    { Controls, HoverData, EditState, selectedFrame, loanCount, activeFormGroup, formGroupChildren },
    dispatch,
  ] = useStateValue();
  const [initValues] = useState(prop);
  const [items, setItems] = useState([{ id: 1, key: "", value: "", name: "" }]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableControl, setDisableControl] = useState(activeFlag);
  const [showItems, setShowItems] = useState("other");
  const [alignStyle, setAlignStyle] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [isFormGroupControl, setIsFormGroupControl] = useState({});
  const layoutId = sessionStorage.getItem("layout_Id");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteFormGropConfirm, setDeleteFormGroupConfirm] = useState({isConfirm: false, from: ""});
  const [FGDeleteReqFrom, setFGDeleteReqFrom] = useState("")
  const [FGDeleteOptionConfigs, setFGDeleteOptionConfigs] = useState({frameId: '', groupBy: '', p_controlId: ''})
  // console.log('FGDeleteOptionConfigs::: ', FGDeleteOptionConfigs);
  // console.log('FGDeleteReqFrom::: ', FGDeleteReqFrom);
  
  const getCountries = async () => {
    try {
      let resp = await Get("common/get/country/ ");
      if (resp?.statusCode === 200) {
        setCountryData(resp?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatesData = async (countryCode) => {
    try {
      let resp = await Get(`common/get/state/${countryCode}`);
      if (resp.statusCode === 200) {
        setItems([]);
        let tempArr = [];
        resp?.data.forEach((ele, i) =>
          tempArr.push({
            id: i + 1,
            key: ele.state,
            value: ele.state_code,
            name: ele.state,
          })
        );
        setItems(tempArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCountries = (code) => {
    if (code) {
      getStatesData(code);
    }
  };

  useEffect(() => {
    if (showItems == "states") {
      getCountries();
    }
  }, [showItems]);

  useEffect(() => {
    if (
      prop?.name === "checkbox" ||
      prop?.type === "radio" ||
      prop?.name === "dropdown"
    ) {
      if (prop?.items[0]?.name !== "") {
        setItems(prop?.items);
      } else {
        switch (prop.name) {
          case "checkbox":
            setItems([
              {
                id: 1,
                key: "checkbox1",
                value: "",
                name: `checkbox_${id}`,
              },
            ]);
            break;
          case "radio":
            setItems([
              {
                id: 1,
                key: "radio1",
                value: "",
                name: `radio_${id}`,
              },
            ]);
            break;
          case "dropdown":
            setItems([
              {
                id: 1,
                key: "option1",
                value: "",
                name: `dropdown_${id}`,
              },
            ]);
            break;
        }
      }
    }
  }, []);

  const addFields = (e, name, type) => {
    e.preventDefault();
    if(type == 'radio') {
      name = name.charAt(0).toUpperCase() + name.slice(1);
      let id = items[items.length - 1].id + 1;
      setItems([
        ...items,
        {
          id: id,
          name: prop?.items[0]?.name,
          key: name + " " + id,
          value: `${name}_${id}`,
          idFor: `f${HoverData.frameId}_radio_i${id}_${HoverData.control_index}`
        },
      ]);
    } else {
      name = name.charAt(0).toUpperCase() + name.slice(1);
      let id = items[items.length - 1].id + 1;
      setItems([
        ...items,
        {
          id: id,
          name: prop?.items[0]?.name,
          key: name + " " + id,
          value: `${name}_${id}`,
        },
      ]);
    }
  };

  const removeFields = (e, id) => {
    // e.preventDefault();
    let fields = [...items];
    if (fields.length > 1) {
      fields.splice(FGDeleteOptionConfigs.groupBy ? FGDeleteOptionConfigs.groupBy : id, 1);
      setItems(fields);
      setValues({ ...initValues, items: [...fields] });
    }
  };

  const handleFieldChanges = (e, id) => {
    e.preventDefault();
    let fields = [...items];
    fields.map((ele) => {
      if (ele.id === id) {
        ele[e.target.name] = e.target.value;
        ele.value = e.target.value;
        // ele.key = `${EditState?.id}_${ele.key}`
      }
      return ele;
    });
    setItems(fields);
  };

  // useEffect(() => {
  //   setValue(prop);
  // }, [Controls, selectedFrame]);

  const { values, handleChange, setValues, handleSubmit, errors } = useFormik({
    initialValues: initValues,
    validate: (values) => {
      const errors = {};

      if (values.minLength?.length == 0) {
        errors.minLength = "Min Length is required";
      } else if (+values.minLength < 0) {
        errors.minLength = "Min length should not have negative numbers";
      }

      if (values.maxLength?.length == 0) {
        errors.maxLength = "Max Length is required";
      } else if (+values.maxLength < 0) {
        errors.maxLength = "Max length should not have negative numbers";
      }

      if (values.minLength && values.maxLength) {
        if (values.minLength > values.maxLength) {
          errors.minLength = "Min length should be less than max length";
        }
      }

      if (values.minValue?.length == 0) {
        errors.minValue = "Min value is required";
      } else if (+values.minValue < 0) {
        errors.minValue = "Min value should not have negative numbers";
      }

      if (values.maxValue?.length == 0) {
        errors.maxValue = "Max value is required";
      } else if (+values.maxValue < 0) {
        errors.maxValue = "Max value should not have negative numbers";
      }

      if (values.minValue && values.maxValue) {
        if (values.minValue > values.maxValue) {
          errors.minValue = "Min value should be less than max value";
        }
      }

      if (initValues?.name === "paragraph") {
        if (!values.fontSize) {
          errors.fontSize = "Font size is required";
        } else if (values.fontSize <= 0) {
          errors.fontSize = "Font size should not be negative value";
        }

        if (!values.paragraph) {
          errors.paragraph = "Paragraph is required";
        }
      }

      return errors;
    },
    onSubmit: async () => {
      values.labelName = values?.labelName?.trim()
      setDisableBtn(true);
      dispatch({
        type: "SET_SAVE",
        item: true,
      });
      let arr = [...Controls];
      if (
        prop?.name === "checkbox" ||
        prop?.name === "dropdown" ||
        prop?.type === "radio"
        ) {
        values.items = items;
      }

      if(Object.keys(activeFormGroup)?.length > 0) {
        arr.map((ele) => {
          if(ele.control_id === activeFormGroup?.control_id) {
            const findFormgroupIndex = arr.indexOf(ele.control_id === activeFormGroup?.control_id ? ele : null)
            ele.properties.isFormGroup_child = true;
            arr[findFormgroupIndex]?.children?.map((elmt) => {
              if(elmt.control_id === EditState?.id) {
                elmt.ref_no = elmt?.control_id;
                elmt.frame_id = ele.frame_id;
                elmt.isformgroup_child = true;
                return elmt.properties = values;
              } else {
                return elmt;
              }
            })
          }
        })
      } else {
        arr.map((ele) => {
          if (ele.control_id === HoverData.id) {
            return (ele.properties = values);
          } else {
            return ele;
          }
        });
      }

      dispatch({
        type: "SET_CONTROL",
        item: arr,
      });

      let all_controls = [];
      arr.map((ele, index) => {
        if (ele?.properties?.type === "file") {
          let obj = {};
          obj.ref_no = ele.control_id ? ele.control_id : 0;
          obj.frame_id = selectedFrame.frame_ref;
          obj.control_name = ele.properties.labelName;
          obj.control_index = index + 1;
          obj.isdefault = ele.isdefault;
          const acceptExtensions = Object.entries(ele?.properties?.fileFormat)
            .filter(([ext, enabled]) => enabled)
            .map(([ext]) => `${ext}`)
            .join("/");
          let newObj = { ...ele.properties, accept: acceptExtensions };
          obj.properties = newObj;
          all_controls.push(obj);
        } else if (ele?.properties?.type === "radio" || ele?.properties?.type === "checkbox") {
          let obj = {};
          // ele.properties?.items?.map((elmt) => {
          //   return elmt.key = `${ele.control_id}_${elmt.key?.replace(" ", "_")}`
          // })
          if(ele.properties.id === isFormGroupControl.id) {
            ele.properties.isFormGroup = isFormGroupControl.value === "yes" ? true : false
          }
          // if(Object.keys(activeFormGroup)?.length > 0) {
          //   console.log('arr11111::: ', arr);
          //   arr.map((ele) => {
          //     if(ele.control_id === activeFormGroup?.control_id) {
          //       const findFormgroupIndex = arr.indexOf(ele.control_id === activeFormGroup?.control_id ? ele : null);
          //        obj.children = arr[findFormgroupIndex]?.children;
          //     }
          //   })
          // }
          
          // SET ALIGNSTYLE FOR FORM GROUP CHILDREN CONTROL IF THAT CHILD TYPE IS RADIO
          if (Object.keys(activeFormGroup)?.length > 0 && ele.properties.type == "radio") {
            ele.children?.map((el) => {
              if (el?.properties?.id === alignStyle?.id) {
                el.properties = {...el.properties, alignStyle: alignStyle.value};
              }
            });
          } else {
            obj.properties = ele.properties.id === alignStyle.id ? {...ele.properties, alignStyle: alignStyle.value} : ele.properties
          }

          obj.ref_no = ele.control_id ? ele.control_id : 0;
          obj.frame_id = selectedFrame.frame_ref;
          obj.control_name = ele.properties.labelName;
          obj.control_index = index + 1;
          obj.isdefault = ele.isdefault;
          obj.isformgroup_child = Object.keys(activeFormGroup)?.length > 0 ? true : false;
          all_controls.push(obj);
        } else {
          let obj = {};
          obj.ref_no = ele.control_id ? ele.control_id : 0;
          obj.frame_id = selectedFrame.frame_ref;
          obj.control_name = ele.properties.labelName;
          obj.control_index = index + 1;
          obj.properties = ele.properties;
          obj.isdefault = ele.isdefault;
          obj.isformgroup_child = Object.keys(activeFormGroup)?.length > 0 ? true : false;
          all_controls.push(obj);
        }
      });

      // Update payload for formgroup child
      let editedFormGroupChild = [];
      let formGroupChild = false;
      if(Object.keys(activeFormGroup)?.length > 0) {
        arr.map((ele) => {
          if(ele.control_id === activeFormGroup?.control_id) {
            formGroupChild = true;
            const findFormgroupIndex = arr.indexOf(ele.control_id === activeFormGroup?.control_id ? ele : null);
              editedFormGroupChild = arr[findFormgroupIndex]?.children?.filter(ele => ele.control_id === EditState.id);
          }
        })
      }

      let resp = await Post(`controls/create`, {
        controls: formGroupChild ? editedFormGroupChild : all_controls,
      });

      if (resp.statusCode == 200) {
        setDisableBtn(false);
        dispatch({
          type: "SET_SAVE",
          item: false,
        });
        dispatch({
          type: "SET_CONTROL",
          item: resp.data,
        });

        // toast.success("Updated Successfully");***
      } else {
        dispatch({
          type: "SET_SAVE",
          item: false,
        });
      }

      //** commented for T-1055
      dispatch({
        type: "SET_HOVER",
        item: { isHovered: false, id: 0, props: {} },
      });
      dispatch({
        type: "SET_EDIT",
        item: {
          isEdit: false,
          currentTab: "profile",
        },
      });
      // */
    },
  });

  // get controls
  const getControls = async () => {
    try {
      let resp = await Get(`controls/getByFrame/${selectedFrame.frame_id}`);
      if (resp.statusCode == 200) {
        dispatch({
          type: "RESET_CONTROLS",
          item: [],
        });

        dispatch({
          type: "SET_CONTROL",
          item: resp.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Delete Control
  const removeField = async (e, id) => {
    if (loanCount === 0) {
      dispatch({
        type: "SET_SAVINGTXT",
        payload: "Saving ...",
      });

      dispatch({
        type: "SET_SAVE",
        item: true,
      });
      let arr;
      if (Object.keys(activeFormGroup)?.length > 0 && formGroupChildren?.length > 0) {
        arr = [...formGroupChildren];
      } else {
        arr = [...Controls];
      }

      let remove = arr.filter((ele) => ele.control_id === HoverData.id);
      let res = await Delete(
        `controls/delete/${remove[0]?.control_id}/${selectedFrame.frame_id}`
      );
      if (res.statusCode == 200) {
        dispatch({
          type: "SET_SAVINGTXT",
          payload: "Saving ...",
        });
        dispatch({
          type: "SET_CONTROL",
          item: res.data,
        });
        dispatch({
          type: "SET_SAVE",
          item: false,
        });
        await updateRadioControllerItemName(res.data, formGroupChildren, dispatch);
      } else {
        dispatch({
          type: "SET_SAVE",
          item: false,
        });
      }
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };
  // else {
  //   dispatch({
  //     type: "SET_CONTROL",
  //     item: [],
  //   });
  // }
  // };

  const EditInputs = () => {
    if (loanCount === 0) {
      dispatch({
        type: "SET_EDIT",
        item: { isEdit: true, id: HoverData.id, currentTab: "design" },
      });
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const handleCancel = () => {
    dispatch({
      type: "SET_HOVER",
      item: { isHovered: false, id: 0, props: {} },
    });
    dispatch({
      type: "SET_EDIT",
      item: { isEdit: false, currentTab: "profile" },
    });
  };

  const getFrames = async () => {
    let res = await Get(`frame/personaFrames/${layoutId}`);
    if (res.statusCode === 200) {
      dispatch({
        type: "FRAME_LENGTH",
        item: res.data.length,
      });
      dispatch({
        type: "GET_FRAME",
        item: res.data,
      });
      res.data?.map((frm) => {
        if (selectedFrame.frame_id === frm.frame_id) {
          dispatch({
            type: "SELECTED_FRAME",
            item: frm,
          });
        }
      });
    } else {
    }
  };

  const handleDisableControl = async (Ct_id) => {
    try {
      if (loanCount === 0) {
        dispatch({
          type: "SET_SAVE",
          item: true,
        });
        let resp = await Get(
          `controls/toggle_active/${Ct_id}/${selectedFrame.frame_id}`
        );
        resp?.data?.map((control) => {
          if (control?.control_id == Ct_id) {
            setDisableControl(control?.active_flag);
          }
        });
        if (resp.statusCode == 200) {
          dispatch({
            type: "SET_SAVE",
            item: false,
          });
          getControls();
          getFrames();
        }
      } else {
        dispatch({
          type: "SET_SAVE",
          item: false,
        });
        dispatch({
          type: "SET_SHOWINFOICON",
          payload: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteControl = (e) => {
    if(prop.type === "radio" && prop.isFormGroup) {
      !showDeleteConfirm && setShowDeleteConfirm(true);
      if(deleteFormGropConfirm.isConfirm && deleteFormGropConfirm.from === "EditDelete") {
        removeField(e);
        setShowDeleteConfirm(false);
      }
    } else {
      removeField(e);
    }
  }

  // DELETE INDIVIDUAL OPTION IN EDIT
  const handleDeleteIndividualOption = (e, propObj, index) => {
    e.preventDefault();
    if (propObj?.name == "radio" && propObj?.isFormGroup) {
      setShowDeleteConfirm(true);
      setFGDeleteReqFrom("optionDelete");
      setFGDeleteOptionConfigs({frameId: HoverData?.frameId, groupBy: index, p_controlId: id});
    } else {
      removeFields(e, index);
    }
  };

  useEffect(() => {
    if (deleteFormGropConfirm.isConfirm && deleteFormGropConfirm.from === "EditDelete"){
      handleDeleteControl();
    } else if (deleteFormGropConfirm.isConfirm && deleteFormGropConfirm.from === "updateControl") {
      handleSubmit();
      setShowDeleteConfirm(false);
    } else if (deleteFormGropConfirm.isConfirm && deleteFormGropConfirm.from === "optionDelete") {
      removeFields();
      setShowDeleteConfirm(false);
    }
  },[deleteFormGropConfirm])
  // console.log('deleteFormGropConfirm::: ', deleteFormGropConfirm);

  const handleCloseFGConfirmPopup = () => {
    setDeleteFormGroupConfirm({isConfirm: false, from: ""});
    setFGDeleteOptionConfigs({frameId: '', groupBy: '', p_controlId: ''});
    setFGDeleteReqFrom("")
    setShowDeleteConfirm(false);
  }

  const handleFGConfirmPopup = () => {
      setDeleteFormGroupConfirm({isConfirm: true, from: FGDeleteReqFrom})
  }

  return (
    <>
      {/* CONFORMATION FOR FORM GROUP DELETE POPUP */}
      <Modal show={showDeleteConfirm} onHide={handleCloseFGConfirmPopup} className="smallpopup">
        <Modal.Header closeButton>
          <Modal.Title>⚠️Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this control? If "Yes" form group controls will be deleted permanently.</Modal.Body>
        <Modal.Footer>
          <div>
            <button className="smallMainButton" 
            onClick={() => handleFGConfirmPopup()}
            >
              Yes
            </button>
          </div>
          <div>
            <button className="smallMainButtonHover" onClick={handleCloseFGConfirmPopup}>
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <Container /> *** */}
      {EditState.isEdit === false ? (
        <>
          {defaultControl &&
          selectedFrame.frame_name != "Personal" &&
          selectedFrame.frame_name != "Financials" ? (
            <ul className="inputSetting">
              <li>
                <button
                  onClick={() => handleDisableControl(controlId)}
                  disabled={!selectedFrame.active_flag}
                  style={
                    !selectedFrame.active_flag
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <img
                    width={20}
                    src={disableControl ? EnableIcon : DisableIcon}
                    alt={disableControl ? "UnlockIcon" : "LockIcon"}
                  />
                </button>
              </li>
            </ul>
          ) : defaultControl === false || 
            (defaultControl && 
              prop?.name == "numberstringaadhar" && frameName == "Personal")||(defaultControl && 
                prop?.name == "numberstringpan" && frameName == "Personal") ? (
            <ul className="inputSetting">
              <li>
                <button
                  onClick={EditInputs}
                  disabled={!activeFlag}
                  style={
                    !activeFlag
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <img src={EditIcon} alt="editicon" />
                </button>
              </li>
              {defaultControl === false && <li>
                <button
                  disabled={!activeFlag}
                  style={
                    !activeFlag
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  onClick={(e) => {
                    handleDeleteControl(e)
                    setFGDeleteReqFrom("EditDelete")
                  }
                  }
                >
                  <img src={DeleteIcon} alt="deleteicon" />
                </button>
              </li>}
            </ul>
          ) : (
            <></>
          )}
        </>
      ) : // {/* Text Field Config*/}
      prop?.name === "text" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Text</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                placeholder="Enter Label Name"
                onChange={handleChange}
                defaultValue={prop?.labelName}
                autoFocus
              />
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Minimum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="minLength"
                defaultValue={prop?.minLength}
                onChange={handleChange}
                min={1}
              />
              {errors.minLength && (
                <span className="errorText">{errors.minLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Maximum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="maxLength"
                defaultValue={prop?.maxLength}
                onChange={handleChange}
                min={1}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                defaultValue={prop?.placeholder}
                onChange={handleChange}
              />
            </div>
            {/* <div className="mb_12" controlid="formBasicCheckbox"> */}
            <div className="smallCheckBox">
              <input
                type="checkbox"
                defaultChecked="true"
                name="required"
                onChange={handleChange}
                defaultValue={prop?.checkbox}
                value={false}
                id="required_true"
              />
              <label htmlFor="required_true">Is the field is mandatory?</label>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
            >
              Update
            </button>
            <button
              style={{ marginLeft: "10px" }}
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </form>
        </div>
      ) : prop?.name === "date" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Date</h5>
          <form>
            <div className="mb_8">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                value={values.labelName}
                onChange={handleChange}
                placeholder="Enter Label Name"
                autoFocus
              />

              <div className="mb_8">
                <label className="smalllabelText">Date Format</label>
                <select
                  className="smallInput"
                  name="dateFormat"
                  id="dateFormat"
                  onChange={handleChange}
                  value={values.dateFormat}
                >
                  <option value="MM/DD/YYYY" selected>
                    MM/DD/YYYY
                  </option>
                  <option value="DD-MM-YYYY">DD/MM/YYYY</option>
                  <option value="YYYY-MM-DD">YYYY/MM/DD</option>
                </select>
              </div>

              <div className="mb_8">
                <label className="smalllabelText">Date Condition</label>
                <select
                  className="smallInput"
                  name="dateCondition"
                  id="dateCondition"
                  onChange={handleChange}
                  value={values.dateCondition}
                >
                  <option value="min_18">Minimum 18 years</option>
                  <option value="min_50">Minimum 50 years</option>
                  <option value="any" selected>
                    Any date
                  </option>
                  <option value="l_cdate">Less than Current Date</option>
                  <option value="g_cdate">Greater than Current Date</option>
                </select>
              </div>
              <div className="mb_12 smallCheckBox">
                <input
                  type="checkbox"
                  defaultChecked={prop?.required}
                  label="Is the field mandatory?"
                  name="required"
                  onChange={handleChange}
                  value={false}
                  id="required_true"
                />
                <label htmlFor="required_true">Is the field mandatory?</label>
              </div>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
              disabled={disableBtn}
              style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
            >
              Update
            </button>
            <button
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
              disabled={disableBtn}
              style={
                disableBtn
                  ? { opacity: 0.5, marginLeft: "10px" }
                  : { opacity: 1, marginLeft: "10px" }
              }
            >
              Cancel
            </button>
          </form>
        </div>
      ) : // {/* File Upload Field Config*/}
      prop?.name === "fileUpload" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">File</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                placeholder="Enter Label Name"
                onChange={handleChange}
                defaultValue={prop?.labelName}
                autoFocus
              />
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">
                Maximum Allowable File Size (in Mb)
              </label>
              <input
                type="number"
                className="smallInput"
                name="maxLength"
                defaultValue={prop?.maxLength}
                onChange={handleChange}
                min={1}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div>
            <div className="mb_8">
              <label className="smalllabelText">File Format</label>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="customAccordion">
                    Select the file formats
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.jpeg}
                        label="jpeg"
                        name="fileFormat.jpeg"
                        onChange={handleChange}
                        value={true}
                        id="jpeg"
                      />
                      <label htmlFor="jpeg">jpeg</label>
                    </div>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.jpg}
                        label="jpg"
                        name="fileFormat.jpg"
                        onChange={handleChange}
                        value={true}
                        id="jpg"
                      />
                      <label htmlFor="jpg">jpg</label>
                    </div>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.pdf}
                        label="pdf"
                        name="fileFormat.pdf"
                        onChange={handleChange}
                        value={true}
                        id="pdf"
                      />
                      <label htmlFor="pdf">pdf</label>
                    </div>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.png}
                        label="png"
                        name="fileFormat.png"
                        onChange={handleChange}
                        value={true}
                        id="png"
                      />
                      <label htmlFor="png">png</label>
                    </div>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.xlsx}
                        label="xlsx"
                        name="fileFormat.xlsx"
                        onChange={handleChange}
                        value={true}
                        id="xlsx"
                      />
                      <label htmlFor="xlsx">xlsx</label>
                    </div>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.csv}
                        label="csv"
                        name="fileFormat.csv"
                        onChange={handleChange}
                        value={true}
                        id="csv"
                      />
                      <label htmlFor="csv">csv</label>
                    </div>
                    <div className="smallCheckBox">
                      <input
                        type="checkbox"
                        defaultChecked={prop?.fileFormat?.zip}
                        label="zip"
                        name="fileFormat.zip"
                        onChange={handleChange}
                        value={true}
                        id="zip"
                      />
                      <label htmlFor="zip">zip</label>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <select
                className="smallInput"
                name="accept"
                id="fileUpload"
                onChange={handleChange}
                value={values.accept}
              >
                <option value="image" selected>
                  Image
                </option>
                <option value="pdf">PDF</option>
              </select> */}
            </div>
            <div className="mb_12" controlid="formBasicCheckbox">
              <div className="smallCheckBox">
                <input
                  type="checkbox"
                  defaultChecked={prop?.required}
                  label="Is the field mandatory?"
                  name="required"
                  onChange={handleChange}
                  value={false}
                  id="required_true"
                />
                <label htmlFor="required_true">Is the field mandatory?</label>
              </div>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
              disabled={disableBtn}
              style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
            >
              Update
            </button>
            <button
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
              disabled={disableBtn}
              style={
                disableBtn
                  ? { opacity: 0.5, marginLeft: "10px" }
                  : { opacity: 1, marginLeft: "10px" }
              }
            >
              Cancel
            </button>
          </form>
        </div>
      ) : // {/* sign field */}
      prop?.name === "sign" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Text</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                placeholder="Enter Label Name"
                onChange={handleChange}
                defaultValue={prop?.labelName}
                autoFocus
              />
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Minimum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="minLength"
                defaultValue={prop?.minLength}
                onChange={handleChange}
                min={1}
              />
              {errors.minLength && (
                <span className="errorText">{errors.minLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Maximum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="maxLength"
                defaultValue={prop?.maxLength}
                onChange={handleChange}
                min={1}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                defaultValue={prop?.placeholder}
                onChange={handleChange}
              />
            </div>
            <div className="mb_12" controlid="formBasicCheckbox">
              <div className="smallCheckBox">
                <input
                  type="checkbox"
                  defaultChecked={prop?.required}
                  label="Is the field mandatory?"
                  name="required"
                  onChange={handleChange}
                  value={false}
                  id="required_true"
                />
                <label htmlFor="required_true">Is the field mandatory?</label>
              </div>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
              disabled={disableBtn}
              style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
            >
              Update
            </button>
            <button
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
              disabled={disableBtn}
              style={
                disableBtn
                  ? { opacity: 0.5, marginLeft: "10px" }
                  : { opacity: 1, marginLeft: "10px" }
              }
            >
              Cancel
            </button>
          </form>
        </div>
      ) : // {/* Paragraph Field Config*/}
      prop?.name === "paragraph" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Paragraph</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              {/* <label className="smalllabelText">Label Name</label> */}
              <textarea
                style={{ marginTop: "10px", fontSize: "12px" }}
                className="samllInput"
                placeholder="Enter a Paragraph"
                name={prop?.name}
                cols="40"
                rows="10"
                onChange={handleChange}
                defaultValue={prop?.paragraph}
                autoFocus
              ></textarea>
              {errors.paragraph && (
                <span className="errorText">{errors.paragraph}</span>
              )}
            </div>

            {/* <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Minimum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="minLength"
                defaultValue={prop?.minLength}
                onChange={handleChange}
                min={1}
              />
              {errors.minLength && (
                <span className="errorText">{errors.minLength}</span>
              )}
            </div> */}
            {/* <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Maximum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="maxLength"
                defaultValue={prop?.maxLength}
                onChange={handleChange}
                min={1}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div> */}
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Font size</label>
              <input
                className="smallInput"
                type="number"
                name="fontSize"
                defaultValue={prop?.fontSize}
                placeholder="Enter a fontsize"
                onChange={handleChange}
              />
              {errors.fontSize && (
                <span className="errorText">{errors.fontSize}</span>
              )}
            </div>
            <div className="mb_12" controlid="formBasicCheckbox">
              <div className="smallCheckBox">
                <input
                  type="checkbox"
                  defaultChecked={prop?.bold}
                  name="bold"
                  onChange={handleChange}
                  value={false}
                  id="bold"
                />
                <label htmlFor="bold">Bold</label>
              </div>
            </div>
            <div className="mb_12" controlid="formBasicCheckbox">
              <div className="smallCheckBox">
                <input
                  type="checkbox"
                  defaultChecked={prop?.italic}
                  name="italic"
                  onChange={handleChange}
                  value={false}
                  id="italic"
                />
                <label htmlFor="italic">Italic</label>
              </div>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
              disabled={disableBtn}
              style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
            >
              Update
            </button>
            <button
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
              disabled={disableBtn}
              style={
                disableBtn
                  ? { opacity: 0.5, marginLeft: "10px" }
                  : { opacity: 1, marginLeft: "10px" }
              }
            >
              Cancel
            </button>
          </form>
        </div>
      ) : prop?.name === "number" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Number</h5>
          <form>
            <div className="mb_8">
              <label className="smalllabelText">Label Name</label>
              <input
                type="text"
                className="smallInput"
                name="labelName"
                onChange={handleChange}
                placeholder="Enter Label Name"
                defaultValue={prop?.labelName}
                autoFocus
              />
            </div>

            <div className="mb_8">
              <label className="smalllabelText">Minimum Value</label>
              <input
                className="smallInput"
                type="number"
                name="minValue"
                onChange={handleChange}
                defaultValue={prop?.minValue}
                min={1}
              />
              {errors.minValue && (
                <span className="errorText">{errors.minValue}</span>
              )}
            </div>

            <div className="mb_8">
              <label className="smalllabelText">Maximum Value</label>
              <input
                className="smallInput"
                type="number"
                name="maxValue"
                onChange={handleChange}
                defaultValue={prop?.maxValue}
                min={1}
              />
              {errors.maxValue && (
                <span className="errorText">{errors.maxValue}</span>
              )}
            </div>

            <div className="mb_8">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                onChange={handleChange}
                defaultValue={prop?.placeholder}
              />
            </div>
            <div className="smallCheckBox mb_12">
              <input
                type="checkbox"
                defaultChecked="true"
                name="required"
                onChange={handleChange}
                defaultValue={prop?.checkbox}
                value={false}
                id="required_true"
              />
              <label htmlFor="required_true">Is the field mandatory?</label>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
              disabled={disableBtn}
              style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
            >
              Update
            </button>
            <button
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
              disabled={disableBtn}
              style={
                disableBtn
                  ? { opacity: 0.5, marginLeft: "10px" }
                  : { opacity: 1, marginLeft: "10px" }
              }
            >
              Cancel
            </button>
          </form>
        </div>
      ) 
      // : prop?.name === "date" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Date</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="labelName"
      //           value={values.labelName}
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Format</label>
      //         <select
      //           className="smallInput"
      //           name="dateFormat"
      //           id="dateFormat"
      //           onChange={handleChange}
      //           value={values.dateFormat}
      //         >
      //           <option value="MM-DD-YYYY" selected>
      //             MM-DD-YYYY
      //           </option>
      //           <option value="DD-MM-YYYY">DD-MM-YYYY</option>

      //           <option value="YYYY-MM-DD">YYYY-MM-DD</option>
      //         </select>
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Condition</label>
      //         <select
      //           className="smallInput"
      //           name="dateCondition"
      //           id="dateCondition"
      //           onChange={handleChange}
      //           value={values.dateCondition}
      //         >
      //           <option value="min_18">Minimum 18 years</option>
      //           <option value="min_50">Minimum 50 years</option>
      //           <option value="any" selected>
      //             Any date
      //           </option>
      //           <option value="l_cdate">Less than Current Date</option>
      //           <option value="g_cdate">Greater than Current Date</option>
      //         </select>
      //       </div>
      //       <div className="mb_12 smallCheckBox">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>

      //       <button
      //         type="submit"
      //         className="smallMainButton"
      //         onClick={handleSubmit}
      //         disabled={disableBtn}
      //         style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //       >
      //         Update
      //       </button>
      //       <button
      //         type="button"
      //         className="smallMainButton"
      //         onClick={handleCancel}
      //         disabled={disableBtn}
      //         style={
      //           disableBtn
      //             ? { opacity: 0.5, marginLeft: "10px" }
      //             : { opacity: 1, marginLeft: "10px" }
      //         }
      //       >
      //         Cancel
      //       </button>
      //     </form>
      //   </div>
      // ) 
      // :prop?.name === "number" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Number</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           type="text"
      //           className="smallInput"
      //           name="labelName"
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           defaultValue={prop?.labelName}
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Minimum Value</label>
      //         <input
      //           className="smallInput"
      //           type="number"
      //           name="minValue"
      //           onChange={handleChange}
      //           defaultValue={prop?.minValue}
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Maximum Value</label>
      //         <input
      //           className="smallInput"
      //           type="number"
      //           name="maxValue"
      //           onChange={handleChange}
      //           defaultValue={prop?.maxValue}
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Placeholder String</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="placeholder"
      //           onChange={handleChange}
      //           defaultValue={prop?.placeholder}
      //         />
      //       </div>
      //       <div className="smallCheckBox mb_12">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           defaultValue={prop?.checkbox}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>
      //       <button
      //         type="submit"
      //         className="smallMainButton"
      //         onClick={handleSubmit}
      //         disabled={disableBtn}
      //         style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //       >
      //         Update
      //       </button>
      //       <button
      //         type="button"
      //         className="smallMainButton"
      //         onClick={handleCancel}
      //         disabled={disableBtn}
      //         style={
      //           disableBtn
      //             ? { opacity: 0.5, marginLeft: "10px" }
      //             : { opacity: 1, marginLeft: "10px" }
      //         }
      //       >
      //         Cancel
      //       </button>
      //     </form>
      //   </div>
      // ) 
      // : prop?.name === "date" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Date</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="labelName"
      //           value={values.labelName}
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Format</label>
      //         <select
      //           className="smallInput"
      //           name="dateFormat"
      //           id="dateFormat"
      //           onChange={handleChange}
      //           value={values.dateFormat}
      //         >
      //           <option value="MM-DD-YYYY" selected>
      //             MM-DD-YYYY
      //           </option>
      //           <option value="DD-MM-YYYY">DD-MM-YYYY</option>

      //           <option value="YYYY-MM-DD">YYYY-MM-DD</option>
      //         </select>
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Condition</label>
      //         <select
      //           className="smallInput"
      //           name="dateCondition"
      //           id="dateCondition"
      //           onChange={handleChange}
      //           value={values.dateCondition}
      //         >
      //           <option value="">Minimum 18 years</option>
      //           <option value="">Minimum 50 years</option>
      //           <option value="" selected>
      //             Any date
      //           </option>
      //           <option value="">Less than Current Date</option>
      //           <option value="">Greater than Current Date</option>
      //         </select>
      //       </div>
      //       <div className="mb_12 smallCheckBox">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>
      //     </form>
      //     <button
      //       type="submit"
      //       className="smallMainButton"
      //       onClick={handleSubmit}
      //       disabled={disableBtn}
      //       style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //     >
      //       Update
      //     </button>
      //     <button
      //       type="button"
      //       className="smallMainButton"
      //       onClick={handleCancel}
      //       disabled={disableBtn}
      //       style={
      //         disableBtn
      //           ? { opacity: 0.5, marginLeft: "10px" }
      //           : { opacity: 1, marginLeft: "10px" }
      //       }
      //     >
      //       Cancel
      //     </button>
      //   </div>
      // )
      : (prop?.name === "checkbox" ||
          prop?.type === "radio" ||
          prop?.name === "dropdown") &&
        frame !== true ? (
        <>
          <form>
            <div className="DesignFrame">
              {prop?.name === "checkbox" ? (
                <h5 className="headingText14 fontWeight_600 mb_8">CheckBox</h5>
              ) : prop?.type === "radio" ? (
                <h5 className="headingText14 fontWeight_600 mb_8">Radio</h5>
              ) : (
                <h5 className="headingText14 fontWeight_600 mb_8">Dropdown</h5>
              )}
              <div className="mb_12">
                <label className="smalllabelText">Label Name</label>
                <input
                  type="text"
                  name="labelName"
                  className="smallInput"
                  value={values.labelName}
                  onChange={handleChange}
                  placeholder="Enter Label Name"
                  defaultValue={prop?.labelName}
                  autoFocus
                />
              </div>
              {(prop.type === "radio" || prop.name === "checkbox") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                    fontSize: "12px",
                  }}
                >
                  <label htmlFor="">Alignment of Options :</label>
                  <div>
                    <input
                      type="radio"
                      id="vertical"
                      name="alignStyle"
                      value="vertical"
                      defaultChecked={
                        prop?.alignStyle === "vertical" ? true : false
                      }
                      onChange={(e) =>
                        setAlignStyle({ id: prop.id, value: e.target.value })
                      }
                    />
                    &nbsp;
                    <label htmlFor="vertical">Vertical</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="horizontal"
                      name="alignStyle"
                      value="horizontal"
                      defaultChecked={
                        prop?.alignStyle === "horizontal" ? true : false
                      }
                      onChange={(e) =>
                        setAlignStyle({ id: prop.id, value: e.target.value })
                      }
                    />
                    &nbsp;
                    <label htmlFor="horizontal">Horizontal</label>
                  </div>
                </div>
              )}
              {(prop.type === "radio" && Object.keys(activeFormGroup).length === 0) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  <label htmlFor="">Is Form Group?</label>
                  <div>
                    <input
                      type="radio"
                      name="isFormGroup"
                      id="yes"
                      value="yes"
                      defaultChecked={prop?.isFormGroup === true ? true : false}
                      onChange={(e) =>
                        setIsFormGroupControl({
                          id: prop?.id,
                          value: e.target.value,
                        })
                      }
                    />
                    &nbsp;
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isFormGroup"
                      id="no"
                      value="no"
                      defaultChecked={
                        prop?.isFormGroup === false ? true : false
                      }
                      onChange={(e) =>
                        setIsFormGroupControl({
                          id: prop?.id,
                          value: e.target.value,
                        })
                      }
                    />
                    &nbsp;
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              )}
              {prop.name === "dropdown" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "5px",
                    fontSize: "12px",
                  }}
                >
                  <div>
                    <input
                      type="radio"
                      name="showDropdown"
                      id="states"
                      value="states"
                      onChange={(e) => setShowItems(e.target.value)}
                    />
                    &nbsp;
                    <label htmlFor="states">States</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="showDropdown"
                      id="other"
                      value="other"
                      defaultChecked
                      onChange={(e) => setShowItems(e.target.value)}
                    />
                    &nbsp;
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
              )}
            </div>
            {showItems == "other" &&
              items.map((item, index) => (
                <div key={index} className="multipleFieldFrame">
                  <div className="displayFlex AlignitemCenter justifyContent_spaceBetween">
                    <div className="headingText14 fontWeight_500">
                      Option {item.id}
                    </div>

                    {items.length !== 1 ? (
                      <div>
                        <button
                          onClick={(e) => handleDeleteIndividualOption(e, prop, index)}
                          className="emptyButton"
                        >
                          <img src={FluentDeleteIcon} />
                        </button>
                      </div>
                    ) : null}
                  </div>

                  <div className="mb_8">
                    <input
                      name="key"
                      className="smallInput"
                      value={item?.key}
                      onChange={(e) => handleFieldChanges(e, item.id)}
                    />
                  </div>
                  {/* <div>
                    <label className="smalllabelText">Value</label>
                    <input
                      name="value"
                      className="smallInput"
                      defaultValue={item?.value}
                      onChange={(e) => handleFieldChanges(e, item.id)}
                    />
                  </div> */}
                </div>
              ))}
            {showItems == "states" && (
              <div style={{ fontSize: "12px", margin: "10px 0 0 10px" }}>
                <label className="smalllabelText">Select Country</label> <br />
                <select className="MainDropdown" onChange={(e) => handleChangeCountries(e.target.value)}>
                  <option value="">-- Select --</option>
                  {countryData?.map((ele, index) => (
                    <option key={index} value={ele?.country_code}>
                      {ele.country}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {showItems == "other" && (
              <button
                style={{ margin: "10px 0 0 10px" }}
                className="smallMainButton"
                onClick={(event) => addFields(event, prop?.name, prop?.type)}
              >
                Add
              </button>
            )}
            <div className="DesignFrame">
              <div className="smallCheckBox mb_24">
                <input
                  type="checkbox"
                  defaultChecked="true"
                  name="required"
                  onChange={handleChange}
                  value={false}
                  id="required_true"
                />
                <label htmlFor="required_true">Is the field mandatory?</label>
              </div>
              <button
                type={prop.type === 'radio' && isFormGroupControl?.value === "no" ? "button" : "submit"}
                className="smallMainButton"
                onClick={() => {
                  if(prop.type === 'radio' && isFormGroupControl?.value === "no") {
                    setShowDeleteConfirm(true);
                    setFGDeleteReqFrom("updateControl");
                  } else {
                    handleSubmit()
                  }
                }}
                disabled={disableBtn}
                style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
              >
                Update
              </button>
              <button
                type="button"
                className="smallMainButton"
                onClick={handleCancel}
                disabled={disableBtn}
                style={
                  disableBtn
                    ? { opacity: 0.5, marginLeft: "10px" }
                    : { opacity: 1, marginLeft: "10px" }
                }
              >
                Cancel
              </button>
            </div>
          </form>
        </>
      ) 
      // : prop?.name === "number" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Number</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           type="text"
      //           className="smallInput"
      //           name="labelName"
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           defaultValue={prop?.labelName}
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Minimum Value</label>
      //         <input
      //           className="smallInput"
      //           type="number"
      //           name="minValue"
      //           onChange={handleChange}
      //           defaultValue={prop?.minValue}
      //           min={1}
      //         />
      //         {errors.minValue && (
      //           <span className="errorText">{errors.minValue}</span>
      //         )}
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Maximum Value</label>
      //         <input
      //           className="smallInput"
      //           type="number"
      //           name="maxValue"
      //           onChange={handleChange}
      //           defaultValue={prop?.maxValue}
      //           min={1}
      //         />
      //         {errors.maxValue && (
      //           <span className="errorText">{errors.maxValue}</span>
      //         )}
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Placeholder String</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="placeholder"
      //           onChange={handleChange}
      //           defaultValue={prop?.placeholder}
      //         />
      //       </div>
      //       <div className="smallCheckBox mb_12">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           defaultValue={prop?.checkbox}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>
      //       <button
      //         type="submit"
      //         className="smallMainButton"
      //         onClick={handleSubmit}
      //         disabled={disableBtn}
      //         style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //       >
      //         Update
      //       </button>
      //       <button
      //         type="button"
      //         className="smallMainButton"
      //         onClick={handleCancel}
      //         disabled={disableBtn}
      //         style={
      //           disableBtn
      //             ? { opacity: 0.5, marginLeft: "10px" }
      //             : { opacity: 1, marginLeft: "10px" }
      //         }
      //       >
      //         Cancel
      //       </button>
      //     </form>
      //   </div>
      // ) 
      // : prop?.name === "date" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Date</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="labelName"
      //           value={values.labelName}
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Format</label>
      //         <select
      //           className="smallInput"
      //           name="dateFormat"
      //           id="dateFormat"
      //           onChange={handleChange}
      //           value={values.dateFormat}
      //         >
      //           <option value="MM-DD-YYYY" selected>
      //             MM-DD-YYYY
      //           </option>
      //           <option value="DD-MM-YYYY">DD-MM-YYYY</option>
      //           <option value="YYYY-MM-DD">YYYY-MM-DD</option>
      //         </select>
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Condition</label>
      //         <select
      //           className="smallInput"
      //           name="dateCondition"
      //           id="dateCondition"
      //           onChange={handleChange}
      //           value={values.dateCondition}
      //         >
      //           <option value="min_18">Minimum 18 years</option>
      //           <option value="min_50">Minimum 50 years</option>
      //           <option value="any" selected>
      //             Any date
      //           </option>
      //           <option value="l_cdate">Less than Current Date</option>
      //           <option value="g_cdate">Greater than Current Date</option>
      //         </select>
      //       </div>
      //       <div className="mb_12 smallCheckBox">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>

      //       <button
      //         type="submit"
      //         className="smallMainButton"
      //         onClick={handleSubmit}
      //         disabled={disableBtn}
      //         style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //       >
      //         Update
      //       </button>
      //       <button
      //         type="button"
      //         className="smallMainButton"
      //         onClick={handleCancel}
      //         disabled={disableBtn}
      //         style={
      //           disableBtn
      //             ? { opacity: 0.5, marginLeft: "10px" }
      //             : { opacity: 1, marginLeft: "10px" }
      //         }
      //       >
      //         Cancel
      //       </button>
      //     </form>
      //   </div>
      // ) 
      // : prop?.name === "number" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Number</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           type="text"
      //           className="smallInput"
      //           name="labelName"
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           defaultValue={prop?.labelName}
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Minimum Value</label>
      //         <input
      //           className="smallInput"
      //           type="number"
      //           name="minValue"
      //           onChange={handleChange}
      //           defaultValue={prop?.minValue}
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Maximum Value</label>
      //         <input
      //           className="smallInput"
      //           type="number"
      //           name="maxValue"
      //           onChange={handleChange}
      //           defaultValue={prop?.maxValue}
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Placeholder String</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="placeholder"
      //           onChange={handleChange}
      //           defaultValue={prop?.placeholder}
      //         />
      //       </div>
      //       <div className="smallCheckBox mb_12">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           defaultValue={prop?.checkbox}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>
      //       <button
      //         type="submit"
      //         className="smallMainButton"
      //         onClick={handleSubmit}
      //         disabled={disableBtn}
      //         style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //       >
      //         Update
      //       </button>
      //       <button
      //         type="button"
      //         className="smallMainButton"
      //         onClick={handleCancel}
      //         disabled={disableBtn}
      //         style={
      //           disableBtn
      //             ? { opacity: 0.5, marginLeft: "10px" }
      //             : { opacity: 1, marginLeft: "10px" }
      //         }
      //       >
      //         Cancel
      //       </button>
      //     </form>
      //   </div>
      // ) 
      // : prop?.name === "date" && frame !== true ? (
      //   <div className="DesignFrame">
      //     <h5 className="headingText14 fontWeight_600">Date</h5>
      //     <form>
      //       <div className="mb_8">
      //         <label className="smalllabelText">Label Name</label>
      //         <input
      //           className="smallInput"
      //           type="text"
      //           name="labelName"
      //           value={values.labelName}
      //           onChange={handleChange}
      //           placeholder="Enter Label Name"
      //           autoFocus
      //         />
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Format</label>
      //         <select
      //           className="smallInput"
      //           name="dateFormat"
      //           id="dateFormat"
      //           onChange={handleChange}
      //           value={values.dateFormat}
      //         >
      //           <option value="MM-DD-YYYY" selected>
      //             MM-DD-YYYY
      //           </option>
      //           <option value="DD-MM-YYYY">DD-MM-YYYY</option>
      //           <option value="YYYY-MM-DD">YYYY-MM-DD</option>
      //         </select>
      //       </div>

      //       <div className="mb_8">
      //         <label className="smalllabelText">Date Condition</label>
      //         <select
      //           className="smallInput"
      //           name="dateCondition"
      //           id="dateCondition"
      //           onChange={handleChange}
      //           value={values.dateCondition}
      //         >
      //           <option value="">Minimum 18 years</option>
      //           <option value="">Minimum 50 years</option>
      //           <option value="" selected>
      //             Any date
      //           </option>
      //           <option value="">Less than Current Date</option>
      //           <option value="">Greater than Current Date</option>
      //         </select>
      //       </div>
      //       <div className="mb_12 smallCheckBox">
      //         <input
      //           type="checkbox"
      //           defaultChecked="true"
      //           name="required"
      //           onChange={handleChange}
      //           value={false}
      //           id="required_true"
      //         />
      //         <label htmlFor="required_true">Is the field mandatory?</label>
      //       </div>
      //     </form>
      //     <button
      //       type="submit"
      //       className="smallMainButton"
      //       onClick={handleSubmit}
      //       disabled={disableBtn}
      //       style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //     >
      //       Update
      //     </button>
      //     <button
      //       type="button"
      //       className="smallMainButton"
      //       onClick={handleCancel}
      //       disabled={disableBtn}
      //       style={
      //         disableBtn
      //           ? { opacity: 0.5, marginLeft: "10px" }
      //           : { opacity: 1, marginLeft: "10px" }
      //       }
      //     >
      //       Cancel
      //     </button>
      //   </div>
      // ) 
      // : (prop?.name === "checkbox" ||
      //     prop?.name === "radio" ||
      //     prop?.name === "dropdown") &&
      //   frame !== true ? (
      //   <>
      //     <form>
      //       <div className="DesignFrame">
      //         {prop?.name === "checkbox" ? (
      //           <h5 className="headingText14 fontWeight_600 mb_8">CheckBox</h5>
      //         ) : prop?.name === "radio" ? (
      //           <h5 className="headingText14 fontWeight_600 mb_8">Radio</h5>
      //         ) : (
      //           <h5 className="headingText14 fontWeight_600 mb_8">Dropdown</h5>
      //         )}
      //         <div className="mb_12">
      //           <label className="smalllabelText">Label Name</label>
      //           <input
      //             type="text"
      //             name="labelName"
      //             className="smallInput"
      //             value={values.labelName}
      //             onChange={handleChange}
      //             placeholder="Enter Label Name"
      //             defaultValue={prop?.labelName}
      //             autoFocus
      //           />
      //         </div>
      //       </div>
      //       {items.map((item, index) => (
      //         <div key={index} className="multipleFieldFrame">
      //           <div className="displayFlex AlignitemCenter justifyContent_spaceBetween">
      //             <div className="headingText14 fontWeight_500">
      //               Option {item.id}
      //             </div>

      //             {items.length !== 1 ? (
      //               <div>
      //                 <button
      //                   onClick={(e) => {
      //                     removeFields(e, index);
      //                   }}
      //                   className="emptyButton"
      //                 >
      //                   <img src={FluentDeleteIcon} />
      //                 </button>
      //               </div>
      //             ) : null}
      //           </div>

      //           <div className="mb_8">
      //             {/* <label className="smalllabelText">Option</label> */}
      //             <input
      //               name="key"
      //               className="smallInput"
      //               defaultValue={item.key}
      //               onChange={(e) => handleFieldChanges(e, item.id)}
      //             />
      //           </div>
      //           {/* <div>
      //             <label className="smalllabelText">Value</label>
      //             <input
      //               name="value"
      //               className="smallInput"
      //               defaultValue={item.value}
      //               onChange={(e) => handleFieldChanges(e, item.id)}
      //             />
      //           </div> */}
      //         </div>
      //       ))}
      //       <button
      //         style={{ margin: "10px 0 0 10px" }}
      //         className="smallMainButton"
      //         onClick={(event) => addFields(event, prop?.name)}
      //       >
      //         Add
      //       </button>
      //       <div className="DesignFrame">
      //         <div className="smallCheckBox mb_24">
      //           <input
      //             type="checkbox"
      //             defaultChecked="true"
      //             name="required"
      //             onChange={handleChange}
      //             value={false}
      //             id="required_true"
      //           />
      //           <label htmlFor="required_true">Is the field mandatory?</label>
      //         </div>
      //         <button
      //           type="submit"
      //           className="smallMainButton"
      //           onClick={handleSubmit}
      //           disabled={disableBtn}
      //           style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
      //         >
      //           Update
      //         </button>
      //         <button
      //           type="button"
      //           className="smallMainButton"
      //           onClick={handleCancel}
      //           disabled={disableBtn}
      //           style={
      //             disableBtn
      //               ? { opacity: 0.5, marginLeft: "10px" }
      //               : { opacity: 1, marginLeft: "10px" }
      //           }
      //         >
      //           Cancel
      //         </button>
      //       </div>
      //     </form>
      //   </>
      // )
       : prop?.name === "password" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Password</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                value={values.labelName}
                onChange={handleChange}
                placeholder="Enter Label Name"
                autoFocus
              />
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Minimum length Required</label>
              <input
                className="smallInput"
                type="number"
                name="minLength"
                value={values.minLength}
                onChange={handleChange}
                min={1}
              />
              {errors.minLength && (
                <span className="errorText">{errors.minLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Maximum length Required</label>
              <input
                className="smallInput"
                type="number"
                name="maxLength"
                value={values.maxLength}
                onChange={handleChange}
                min={1}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                value={values.placeholder}
                onChange={handleChange}
              />
            </div>
            <div className="smallCheckBox mb_12">
              <input
                type="checkbox"
                defaultChecked="true"
                label="Is the field mandatory?"
                name="required"
                onChange={handleChange}
                value={false}
                id="required_true"
              />

              <label htmlFor="required_true">Is the field mandatory?</label>
            </div>
          </form>
          <button
            type="submit"
            className="smallMainButton"
            onClick={handleSubmit}
            disabled={disableBtn}
            style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
          >
            Update
          </button>
          <button
            type="button"
            className="smallMainButton"
            onClick={handleCancel}
            disabled={disableBtn}
            style={
              disableBtn
                ? { opacity: 0.5, marginLeft: "10px" }
                : { opacity: 1, marginLeft: "10px" }
            }
          >
            Cancel
          </button>
        </div>
      ) : prop?.name === "numberstringphone" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Phone</h5>
          <div onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                value={values.labelName}
                onChange={handleChange}
                placeholder="Enter Label Name"
                autoFocus
              />
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Minimum length Required</label>
              <input
                className="smallInput"
                type="number"
                name="minLength"
                value={values.minLength}
                onChange={handleChange}
                min={1}
              />
              {errors.minLength && (
                <span className="errorText">{errors.minLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Maximum length Required</label>
              <input
                className="smallInput"
                type="number"
                name="maxLength"
                value={values.maxLength}
                onChange={handleChange}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                value={values.placeholder}
                onChange={handleChange}
              />
            </div>
            <div className="smallCheckBox mb_12">
              <input
                type="checkbox"
                defaultChecked="true"
                label="Is the field mandatory?"
                name="required"
                onChange={handleChange}
                value={false}
                id="required_true"
              />
              <label htmlFor="required_true">Is the field mandatory?</label>
            </div>
          </div>
          <button
            type="submit"
            className="smallMainButton"
            onClick={handleSubmit}
            disabled={disableBtn}
            style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
          >
            Update
          </button>
          <button
            type="button"
            className="smallMainButton"
            onClick={handleCancel}
            disabled={disableBtn}
            style={
              disableBtn
                ? { opacity: 0.5, marginLeft: "10px" }
                : { opacity: 1, marginLeft: "10px" }
            }
          >
            Cancel
          </button>
        </div>
      ) : prop?.name === "numberstringaadhar" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Aadhar</h5>
          <div onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                value={values.labelName}
                onChange={handleChange}
                placeholder="Enter Label Name"
                disabled={prop?.name == "numberstringaadhar" && defaultControl ? true : false}
                autoFocus
              />
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                value={values.placeholder}
                onChange={handleChange}
                disabled={prop?.name == "numberstringaadhar" && defaultControl ? true : false}
              />
            </div>
            <div className="smallCheckBox mb_12">
              <input
                type="checkbox"
                defaultChecked={prop.required}
                label="Is the field mandatory?"
                name="required"
                onChange={handleChange}
                value={false}
                id="required_true"
              />
              <label htmlFor="required_true">Is the field mandatory?</label>
            </div>
          </div>
          <button
            type="submit"
            className="smallMainButton"
            onClick={handleSubmit}
            disabled={disableBtn}
            style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
          >
            Update
          </button>
          <button
            type="button"
            className="smallMainButton"
            onClick={handleCancel}
            disabled={disableBtn}
            style={
              disableBtn
                ? { opacity: 0.5, marginLeft: "10px" }
                : { opacity: 1, marginLeft: "10px" }
            }
          >
            Cancel
          </button>
        </div>
      ) : prop?.name === "numberstringpan" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Pan</h5>
          <div onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                value={values.labelName}
                onChange={handleChange}
                placeholder="Enter Label Name"
                disabled={prop?.name == "numberstringpan" && defaultControl ? true : false}
                autoFocus
              />
            </div>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                value={values.placeholder}
                onChange={handleChange}
                disabled={prop?.name == "numberstringpan" && defaultControl ? true : false}
              />
            </div>
            <div className="smallCheckBox mb_12">
              <input
                type="checkbox"
                defaultChecked={prop.required}
                label="Is the field mandatory?"
                name="required"
                onChange={handleChange}
                value={false}
                id="required_true"
              />
              <label htmlFor="required_true">Is the field mandatory?</label>
            </div>
          </div>
          <button
            type="submit"
            className="smallMainButton"
            onClick={handleSubmit}
            disabled={disableBtn}
            style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
          >
            Update
          </button>
          <button
            type="button"
            className="smallMainButton"
            onClick={handleCancel}
            disabled={disableBtn}
            style={
              disableBtn
                ? { opacity: 0.5, marginLeft: "10px" }
                : { opacity: 1, marginLeft: "10px" }
            }
          >
            Cancel
          </button>
        </div>
      ) : prop?.name === "email" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Email</h5>
          <div onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                value={values.labelName}
                onChange={handleChange}
                placeholder="Enter Label Name"
                autoFocus
              />
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                value={values.placeholder}
                onChange={handleChange}
              />
            </div>
            <div className="smallCheckBox mb_12">
              <input
                type="checkbox"
                defaultChecked="true"
                label="Is the field mandatory?"
                name="required"
                onChange={handleChange}
                value={false}
                id="required_true"
              />
              <label htmlFor="required_true">Is the field mandatory?</label>
            </div>
          </div>
          <button
            type="submit"
            className="smallMainButton"
            onClick={handleSubmit}
            disabled={disableBtn}
            style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
          >
            Update
          </button>
          <button
            type="button"
            className="smallMainButton"
            onClick={handleCancel}
            disabled={disableBtn}
            style={
              disableBtn
                ? { opacity: 0.5, marginLeft: "10px" }
                : { opacity: 1, marginLeft: "10px" }
            }
          >
            Cancel
          </button>
        </div>
      ) : prop?.name === "textarea" && frame !== true ? (
        <div className="DesignFrame">
          <h5 className="headingText14 fontWeight_600">Textarea</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Label Name</label>
              <input
                className="smallInput"
                type="text"
                name="labelName"
                placeholder="Enter Label Name"
                onChange={handleChange}
                defaultValue={prop?.labelName}
                autoFocus
              />
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Minimum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="minLength"
                value={values.minLength}
                onChange={handleChange}
                defaultValue={prop?.minLength}
                min={1}
              />
              {errors.minLength && (
                <span className="errorText">{errors.minLength}</span>
              )}
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Maximum length Required</label>
              <input
                type="number"
                className="smallInput"
                name="maxLength"
                value={values.maxLength}
                onChange={handleChange}
                defaultValue={prop?.maxLength}
                min={1}
              />
              {errors.maxLength && (
                <span className="errorText">{errors.maxLength}</span>
              )}
            </div>

            <div className="mb_8" controlid="exampleForm.ControlInput1">
              <label className="smalllabelText">Placeholder String</label>
              <input
                className="smallInput"
                type="text"
                name="placeholder"
                onChange={handleChange}
                defaultValue={prop?.placeholder}
              />
            </div>
            <div className="mb_12" controlid="formBasicCheckbox">
              <div className="smallCheckBox">
                <input
                  type="checkbox"
                  defaultChecked="true"
                  label="Is the field mandatory?"
                  name="required"
                  onChange={handleChange}
                  value={false}
                  id="required_true"
                />
                <label htmlFor="required_true">Is the field mandatory?</label>
              </div>
            </div>
            <button
              type="submit"
              className="smallMainButton"
              onClick={handleSubmit}
              disabled={disableBtn}
              style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
            >
              Update
            </button>
            <button
              type="button"
              className="smallMainButton"
              onClick={handleCancel}
              disabled={disableBtn}
              style={
                disableBtn
                  ? { opacity: 0.5, marginLeft: "10px" }
                  : { opacity: 1, marginLeft: "10px" }
              }
            >
              Cancel
            </button>
          </form>
        </div>
      ) : null}
    </>
  );
};
export default EditAndDelete;
