import React, { useState, useEffect, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const InputField = (props) => {
  const {
    type,
    id,
    name,
    labelName,
    required,
    minLength,
    maxLength,
    placeholder,
    pattern,
    min,
    max,
    htmlFor,
    itemvalues,
    dateFormat,
    dateCondition,
    isDefault,
    defaultLayout,
    activeFlag,
    alignStyle,
    isFormGroup,
    updateSelectedFormGroupOption
  } = props;
  // console.log('props::: ', props);


  const [card, setCard] = useState();
  const [inputvalue, setInputValue] = useState("");
  const [inputAadhar, setInputAadharValue] = useState("");
  const [inputPan, setInputPanValue] = useState("");

  const inputCard = useRef();
  const [value, setValue] = useState(null);
  const [minDate, setminDate] = useState("");
  const [maxDate, setmaxDate] = useState("");
  const [yearerr, setYearerr] = useState({ isErr: false, errTxt: "" });
  // const [yearerr1, setYearerr1] = useState("");
  const [inputNum, setInputNum] = useState("");

  // remove wheel scroll for number input
  useEffect(() => {
    document.addEventListener("wheel", () => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  const handleChanged = (newValue) => {
    var valyear = dayjs().year() - dayjs(newValue).year();
    let d = new Date(newValue);
    if (dateCondition == "min_18") {
      if (Math.abs(new Date() - d) >= 567993600000) {
        setValue(newValue);
        setYearerr({ isErr: false, errTxt: "" });
      } else {
        setYearerr({
          isErr: true,
          errTxt: "You must be 18 years old or above",
        });
      }
    } else if (dateCondition == "min_50") {
      if (valyear >= 50) {
        setValue(newValue);
        setYearerr({ isErr: false, errTxt: "" });
      } else {
        setYearerr({
          isErr: true,
          errTxt: "You must be 50 years old or above",
        });
      }
    } else if (dateCondition == "any") {
      setValue(newValue);
      setmaxDate(null);
      setYearerr({ isErr: false, errTxt: "" });
    } else if (dateCondition == "l_cdate") {
      setValue(newValue);
      if (new Date(newValue?.$d) > new Date()) {
        setYearerr({
          isErr: true,
          errTxt: "Should not be greater than current date",
        });
      } else {
        setYearerr({ isErr: false, errTxt: "" });
      }
    } else if (dateCondition == "g_cdate") {
      setValue(newValue);
      if (new Date(newValue?.$d) < new Date()) {
        setYearerr({
          isErr: true,
          errTxt: "Should be greater than current date",
        });
      } else {
        setYearerr({ isErr: false, errTxt: "" });
      }
    }
  };

  useEffect(() => {
    let today = new Date();
    let age18 = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    let age50 = new Date(
      today.getFullYear() - 50,
      today.getMonth(),
      today.getDate()
    );
    if (dateCondition == "min_18") {
      setmaxDate(age18);
    } else if (dateCondition == "min_50") {
      setmaxDate(age50);
    } else if (dateCondition == "g_cdate") {
      setminDate(new Date());
      setmaxDate(null);
    } else if (dateCondition == "l_cdate") {
      setminDate(null);
      setmaxDate(new Date());
    } else if (dateCondition == "any") {
      setminDate(null);
      setmaxDate(null);
    }
    if (dateCondition == "l_cdate") {
      if (maxDate == "") {
        var minyear = dayjs().year();
        var minmonth = dayjs().month() + 1;
        var mindate = dayjs().date();
        if (mindate == 1) {
          var int_d = new Date(minyear, minmonth - 1, mindate);
          let d = new Date(int_d - 1).getDate();
          let mnt = new Date(int_d - 1).getMonth() + 1;
          let yrs = new Date(int_d - 1).getFullYear();
          mindate = d;
          minmonth = mnt;
          minyear = yrs;
          if (minmonth < 10) {
            minmonth = "0" + String(minmonth);
          }
          if (mindate < 10) {
            mindate = "0" + String(mindate);
          }
          var fmindate =
            String(minyear) + "-" + String(minmonth) + "-" + String(mindate);
          setmaxDate(fmindate);
        } else {
          if (minmonth < 10) {
            minmonth = "0" + String(minmonth);
          }
          if (mindate < 10) {
            mindate = "0" + String(mindate);
          }
          var fmindate =
            String(minyear) + "-" + String(minmonth) + "-" + String(mindate);
          setmaxDate(fmindate);
        }
      }
    }

    if (dateCondition == "g_cdate") {
      if (maxDate == "") {
        // var tdate = new Date();
        // tdate.setDate(tdate.getDate() + 1);
        // tdate = tdate.toISOString().split("T")[0];
        setminDate(new Date());
        setmaxDate(null);
      }
    }
  }, [dateCondition]);

  // useEffect(() => {}, [card]);
  //ssn mask
  // const handleChange = () => {
  //   const cardValue = inputCard?.current?.value
  //     .replace(/\D/g, "")
  //     .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
  //   inputCard.current.value = !cardValue[2]
  //     ? cardValue[1]
  //     : `${cardValue[1]}-${cardValue[2]}${`${
  //         cardValue[3] ? `-${cardValue[3]}` : ""
  //       }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
  //   const numbers = inputCard?.current.value.replace(/(\D)/g, "");
  //   setCard(numbers);
  // };

  //mobile mask
  const PhoneNumberFormatter = (num) => {
    if (!num) return num;
    const PhoneNumber = num.replace(/[^\d]/g, "");
    if (PhoneNumber.length === 0) {
      return null;
    } else if (PhoneNumber.length > 0 && PhoneNumber.length < 3) {
      return `(${PhoneNumber}`;
    } else if (PhoneNumber.length >= 4 && PhoneNumber.length < 6) {
      return `(${PhoneNumber.slice(0, 3)}) ${PhoneNumber.slice(3)}`;
    } else if (PhoneNumber.length >= 7) {
      return `(${PhoneNumber.slice(0, 3)}) ${PhoneNumber.slice(
        3,
        6
      )}-${PhoneNumber.slice(6, 10)}`;
    }
  };


  const AadharFormatter = (num) => {
    const sanitized = num.replace(/\D/g, ""); // Remove non-digits
    const match = sanitized.match(/(\d{0,4})(\d{0,4})(\d{0,4})/); // Match groups of up to 4 digits
    if (!match) return sanitized; // Return sanitized value if no match

    const [, group1, group2, group3] = match;
    return [group1, group2, group3].filter(Boolean).join("-");
  };

  const PanFormatter = (num) => {
    const sanitized = num.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
    const match = sanitized.match(/([A-Z]{0,5})(\d{0,4})([A-Z]{0,1})/i); // Match PAN format
    if (!match) return sanitized.toUpperCase(); // Return sanitized value if no match
  
    const [, group1, group2, group3] = match;
    return [group1, group2, group3].filter(Boolean).join("-").toUpperCase(); // Join non-empty groups with "-"
  };




  const handleInput = (e) => {
    const formattedPhoneNumber = PhoneNumberFormatter(e.target.value);
    setInputValue(formattedPhoneNumber);
  };

  const handleAadharInput = (e) => {
    const formattedAadharNumber = AadharFormatter(e.target.value);
    setInputAadharValue(formattedAadharNumber);
  };

  const handlePanInput = (e) => {
    const formattedPanNumber = PanFormatter(e.target.value);
    setInputPanValue(formattedPanNumber);
  };

  const handleChangeNumber = (e) => {
    setInputNum(e.target.value?.slice(0, max));
  };

  return (
    <>
      {type !== "textarea" &&
        type !== "checkbox" &&
        type !== "dropdown" &&
        type !== "radio" &&
        type !== "number" &&
        type !== "date" &&
        name !== "numberstringaadhar" &&
        name !== "numberstringpan" &&

        name !== "numberstringphone" && (
          <>
            <label
              className="biglabelText"
              htmlFor={id}
              draggable={isDefault && defaultLayout ? "false" : null}
            >
              {labelName}
            </label>
            <div
              className="no-arrows"
              draggable={isDefault && defaultLayout ? "false" : null}
            >
              <input
                type={type}
                id={id}
                placeholder={placeholder}
                minLength={minLength}
                maxLength={maxLength}
                min={min}
                max={max}
                pattern={pattern}
                required={required}
                className={type !== "file" ? "MainInput" : null}
                draggable={isDefault && defaultLayout ? "false" : null}
                disabled={activeFlag ? false : true}
              />
            </div>
          </>
        )}
      {name === "number" && (
        <>
          <label
            className="biglabelText"
            htmlFor={id}
            draggable={isDefault && defaultLayout ? "false" : null}
          >
            {labelName}
          </label>
          <input
            value={inputNum}
            onChange={(e) => handleChangeNumber(e)}
            type={type}
            id={id}
            placeholder={placeholder}
            minLength={min}
            maxLength={max}
            min={min}
            max={max}
            pattern={pattern}
            required={required}
            className={"MainInput"}
            draggable={isDefault && defaultLayout ? "false" : null}
            disabled={activeFlag ? false : true}
          />
        </>
      )}
      {name === "numberstringaadhar" && (
        <>
          <label
            className="biglabelText"
            htmlFor={id}
            draggable={isDefault && defaultLayout ? "false" : null}
          >
            {labelName}
          </label>
          <input
            value={inputAadhar}
            onChange={(e) => handleAadharInput(e)}
            type={type}
            id={id}
            placeholder={placeholder}
            minLength={minLength}
            maxLength={maxLength}
            min={min}
            max={max}
            pattern={pattern}
            required={required}
            className="MainInput"
            draggable={isDefault && defaultLayout ? "false" : null}
            disabled={activeFlag ? false : true}
          />
        </>
      )}
       {name === "numberstringpan" && (
        <>
          <label
            className="biglabelText"
            htmlFor={id}
            draggable={isDefault && defaultLayout ? "false" : null}
          >
            {labelName}
          </label>
          <input
            value={inputPan}
            onChange={(e) => handlePanInput(e)}
            type={type}
            id={id}
            placeholder={placeholder}
            minLength={minLength}
            maxLength={maxLength}
            min={min}
            max={max}
            pattern={pattern}
            required={required}
            className="MainInput"
            draggable={isDefault && defaultLayout ? "false" : null}
            disabled={activeFlag ? false : true}
          />
        </>
      )}
      {name === "numberstringphone" && (
        <>
          <label
            className="biglabelText"
            htmlFor={id}
            draggable={isDefault && defaultLayout ? "false" : null}
          >
            {labelName}
          </label>
          <input
            value={inputvalue}
            onChange={(e) => handleInput(e)}
            type={type}
            id={id}
            placeholder={placeholder}
            minLength={minLength}
            maxLength={maxLength}
            min={min}
            max={max}
            pattern={pattern}
            required={required}
            className="MainInput"
            draggable={isDefault && defaultLayout ? "false" : null}
            disabled={activeFlag ? false : true}
          />
        </>
      )}
      <div style={alignStyle === "horizontal" ? 
            {display: 'flex', alignItems: "center", gap: '15px', flexWrap: 'wrap'} : {}}
      >
        {type === "checkbox" || type === "radio"
          ? itemvalues?.map((ele, index) => {
              return (
                <div
                  draggable={isDefault && defaultLayout ? "false" : null}
                  className={
                    type === { type }
                      ? "tickmark"
                      : type === { type }
                      ? "prefer_method"
                      : null
                  }
                >
                  <input
                    draggable={isDefault && defaultLayout ? "false" : null}
                    id={type == 'radio' ? ele.idFor : ele.value}
                    type={type}
                    name={ele.name ? ele.name : ""}
                    disabled={activeFlag ? false : true}
                    onChange={(e) =>
                      type === "radio" && isFormGroup &&
                      updateSelectedFormGroupOption({
                        control_id: id,  
                        uuid: ele.id,
                        id: ele.idFor,
                        selected: ele.key,
                        type,
                        labelName,
                        isFormGroup
                      })
                    }
                  />
                  <label
                    className="biglabelText pl10"
                    key={index}
                    htmlFor={type == 'radio' ? ele.idFor : ele.value}
                    draggable={isDefault && defaultLayout ? "false" : null}
                  >
                    {ele.key}
                  </label>
                </div>
              );
            })
          : null}
      </div>

      {type == "date" && (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={1} className="customDatepicker-container">
              <label
                draggable={isDefault && defaultLayout ? "false" : null}
                className="biglabelText customDatapicker-label"
                htmlFor={id}
              >
                {labelName}
              </label>
              <DesktopDatePicker
                draggable={isDefault && defaultLayout ? "false" : null}
                inputFormat={dateFormat}
                minDate={dayjs(minDate)}
                maxDate={dayjs(maxDate)}
                value={value}
                className="customDatapicker"
                onChange={handleChanged}
                disabled={activeFlag ? false : true}
                renderInput={(params) => <TextField {...params} />}
              />
              {yearerr.isErr == true && (
                <h4
                  className="errorText"
                  draggable={isDefault && defaultLayout ? "false" : null}
                >
                  {yearerr.errTxt}
                </h4>
              )}
            </Stack>
          </LocalizationProvider>
        </>
      )}
    </>
  );
};
export default InputField;
