import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "../StateProvider/StateProvider";
import Frame from "./Frame";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MainLogo from "../assets/images/theecode-logo.svg";
import RadioCheckedIcon from "../assets/images/radioButton_icon.svg";
import CheckboxIcon from "../assets/images/checkbox_icon.svg";
import EmailIcon from "../assets/images/mail_icon.svg";
import SpinnerIcon from "../assets/images/fa_spinner.svg";
import TextareaIcon from "../assets/images/textarea_icon.svg";
import PasswordIcon from "../assets/images/password_icon.svg";
import DropdownIcon from "../assets/images/dropdown_icon.svg";
import DateIcon from "../assets/images/date_icon.svg";
import StringIcon from "../assets/images/string_icon.svg";
// import SignIcon from "../assets/images/signIcon.svg";
import InputNumberIcon from "../assets/images/input_number_icon.svg";
import InputFieldIcon from "../assets/images/input_field_icon.svg";
import ComponentIcon from "../assets/images/component_icon.svg";
import BrushIcon from "../assets/images/brush_icon.svg";
import FluentEditIcon from "../assets/images/fluent_edit_icon.svg";
import AddBoxIcon from "../assets/images/addBox_icon.svg";
import FluentDeleteIcon from "../assets/images/fluentDelete_icon.svg";
import saveIcon from "../assets/images/saveIcon.svg";
import MinusIcon from "../assets/images/minus_icon.svg";
// import LogoutIcon from "../assets/images/logout_icon.svg";
import PhoneIcon from "../assets/images/phone_icon.svg";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import {   useNavigate, useParams } from "react-router-dom";
import { Get, Post,  Delete } from "../_common/httpService";
import initialValues from "../Components/EditAndDelete/initialValues";
import EditAndDelete from "../Components/EditAndDelete/EditDelete";
import closeIcon from "../assets/images/closeIcon.svg";
// import LockIcon from "../assets/images/lockIcon.svg";
// import UnlockIcon from "../assets/images/unlockIcon.svg";
// import FileuploadIcon from "../assets/images/fileuploadIcon.svg";
import moveiconIcon from "../assets/images/move_icon.svg";
import layoutIcon from "../assets/images/layout-icon.svg";
import LayoutWorkflowMapping from "../Components/LayoutWorkflowMapping/LayoutWorkflowMapping";
import { updateRadioControllerItemName } from "../Components/updateRadioControllerItemName";
const Controls = () => {
  const navigate = useNavigate();
  // const location = useLocation()
  // console.log('location::: ', window.history);
  const [
    {
      Controls,
      HoverData,
      EditState,
      Preview,
      FrameData,
      selectedFrame,
      isSaving,
      savingTxt,
      loanCount,
      selectedLayoutId,
      layoutName,
      controlsNotDraggable,
      activeFormGroup,
      formGroupChildren,
      payloadArr,
      nextControlIndex,
      layoutUniqueId
    },
    dispatch,
  ] = useStateValue();
  const [currentTab, setCurrentTab] = useState("profile");
  const [addframe, setAddframe] = useState(false);
  const [editframe, setEditframe] = useState({
    isEditActive: false,
    id: 0,
  });

  const [layout, setLayout] = useState([]);
  const [layoutArr, setLayoutArr] = useState([]);
  const [layoutid, setLayoutId] = useState("");
  // const [layoutUniqueId, setLayoutUniqueId] = useState("");
  const [ref, setRef] = useState("");
  const [frameindex, setFrameIndex] = useState("");
  const [frameid, setFrameId] = useState("");
  const [show, setShow] = useState(false);
  const [showadd, setShowadd] = useState(true);
  const [frameName, setFrameName] = useState("");
  const [currFrame, setCurrFrame] = useState("");
  const [currFrameId, setCurrFrameId] = useState("");
  const [edt, setEdt] = useState(false);
  const [frameID, setFrameID] = useState("");
  const [editIconClicked, setEditIconClicked] = useState(false);
  const [disableCreateBtn, setDisableCreateBtn] = useState(false);
  const { id } = useParams();
  const is_default = JSON.parse(sessionStorage.getItem("isDefault"));
  const isDef = sessionStorage.getItem("isDefault");
  // const [Assignshow, setAssignShow] = useState(false);
  // const handleAssignClose = () => setAssignShow(false);
  // const handleAssignShow = () => setAssignShow(true);
  const handleClose = () => setShow(false);
  sessionStorage.setItem("personaId", id);
  let layoutId = sessionStorage.getItem("layout_Id") || layoutUniqueId;
  const defaultLayout = isDef && JSON.parse(isDef);

  const getLayoutName = async () => {
    let res = await Get(`layout/getbylayout_id/${layoutId}`);
    if (res.statusCode === 200) {
      // console.log(res.data);
      sessionStorage.setItem("lref", res.data?.ref_no);
      sessionStorage.setItem("lname", res.data?.layout_name);
      sessionStorage.setItem("layout_Id", res.data?.id);
      sessionStorage.setItem("isDefault", res.data?.isdefault);
      dispatch({
        type: "SET_LAYOUTNAME",
        payload: res.data.layout_name,
      });
    }
  };

  const getLoanCount = async () => {
    try {
      let res = await Get(`layout/loancount/${layoutId}`);
      if (res.statusCode === 200) {
        dispatch({
          type: "SET_LOANCOUNT",
          payload: +res.loanCount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (layoutUniqueId) {
      getLoanCount();
    }
  }, [layoutUniqueId]);

  //getframes
  useEffect(() => {
    if (layoutUniqueId) {
      getLayoutName();
      getFrames();
    }
  }, [selectedLayoutId, layoutUniqueId]);

  useEffect(() => {
    if (EditState.isEdit === true) {
      setCurrentTab("design");
    } else {
      setCurrentTab("profile");
    }
  }, [EditState]);

  const StateUpdate = () => {
    dispatch({
      type: "SET_INPUT",
      item: Controls,
    });
  };

  const changeTabs = (key) => {
    setCurrentTab(key);
    if (key === "profile") {
      dispatch({
        type: "SET_EDIT",
        item: {
          isEdit: false,
          currentTab: "profile",
        },
      });
    }
  };

  const getInput = async (e, element) => {
    if (selectedFrame?.frame_name !== "Bank Lookup") {
      if (loanCount == 0) {
        dispatch({
          type: "SET_DRAGSTARTFROM",
          payload: "control",
        });
        if (selectedFrame.frame_id !== undefined && !defaultLayout) {
          e.dataTransfer.setData(`${element}`, element);
          e.dataTransfer.dropEffect = "move";
          const nextIndex = sessionStorage.getItem("nextIndex");
          let arr;
          let obj;
          if(Object.keys(activeFormGroup).length > 0) {
            arr = [...formGroupChildren];
            obj = initialValues(element, Number(nextIndex)+1, selectedFrame?.frame_ref, true, activeFormGroup?.uuid);
          } else {
            arr = [...Controls];
            obj = initialValues(element, Number(nextIndex)+1, selectedFrame?.frame_ref, false);
          }
          
          // let obj = initialValues(element, arr?.length + payloadArr?.length + 1);\
          if (element === "fileUpload") { 
            const acceptExtensions = Object.entries(obj.fileFormat)
              .filter(([ext, enabled]) => enabled)
              .map(([ext]) => `${ext}`)
              .join("/");
            obj.id = arr.length;
            // obj.name = element;
            let newObj = { ...obj, accept: acceptExtensions };
            arr.push({ properties: newObj });
          } else {
            obj.id = arr.length;
            // obj.name = element;
            arr.push({ properties: obj });
          }
          
          if(Object.keys(activeFormGroup).length === 0) {
            dispatch({
              type: "SET_CONTROLDUM",
              item: arr,
            });
          }
          arr.map(async (ele, index) => {
            let obj = {};
            if (ele.control_id === undefined) {
              obj.ref_no = 0;
              obj.frame_id = selectedFrame.frame_ref;
              obj.control_name = ele.properties.labelName;
              obj.control_index = index + 1;
              obj.properties = ele.properties;
              obj.isformgroup_child = false;
              obj.isdefault = is_default ? true : false;

              dispatch({
                type: "PASS_OBJECT",
                item: obj,
              });
            }
          });
        }
      } else {
        dispatch({
          type: "SET_SHOWINFOICON",
          payload: true,
        });
      }
    }
  };

  const gotoPreview = () => {
    navigate("/preview");
  };
  const gotoForm = () => {
    dispatch({
      type: "SET_PREVIEW",
      item: false,
    });
  };

  // POST FRAME

  const postFrames = async (payloadData) => {
    let res = await Post(`frame/create/frame`, payloadData);
    if (res.statusCode === 200) {
      dispatch({
        type: "FRAME_LENGTH",
        item: res.data.length,
      });
      setLayout(res.data[0]?.layout_ref);
      dispatch({
        type: "GET_FRAME",
        item: res.data,
      });
    }
  };

  //get frames
  const getFrames = async () => {
    let res = await Get(`frame/personaFrames/${layoutId}`);
    if (res.statusCode === 200) {
      dispatch({
        type: "FRAME_LENGTH",
        item: res.data.length,
      });
      setLayout(res.data[0]?.layout_ref);
      // if (currFrame == "") {
      if (res.data[0]?.frame_name) {
        setCurrFrame(res.data[0]?.frame_name);
        setCurrFrameId(res.data[0]?.frame_id);
        let empt = "";
        handleFrameSelect(empt, res.data[0]);
        // } else {
        //   setCurrFrame("");
        // }
      }
      dispatch({
        type: "GET_FRAME",
        item: res.data,
      });
    } else {
    }
  };
  const addFrame = () => {
    if (loanCount == 0) {
      formik.setErrors({});
      setAddframe(true);
      setShowadd(false);
      setEditframe({ isEditActive: false, id: 0 });
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const removeFrame = () => {
    formik.setErrors({});
    setAddframe(false);
    setShowadd(true);
  };

  //add frame
  const formik = useFormik({
    initialValues: {
      frames: [
        {
          frame_name: "",
          frame_index: 0,
          layout_id: "",
          ref_no: 0,
        },
      ],
    },
    validate: (values) => {
      const errors = {};
      values.frames.map((frame) => {
        if (frame.frame_name.trim().length === 0) {
          errors.frame_name = "Frame Name should not be empty";
        } else if (!isNaN(Number(frame.frame_name[0]))) {
          errors.frame_name = "Frame Name must be start with Alphabet";
        } else if (new RegExp('^[0-9]+$').test(frame.frame_name)) {
          errors.frame_name = "Frame Name must be alpha numeric";
        } else if (!/^[a-zA-Z0-9/-\s]*$/.test(frame.frame_name)) {
          errors.frame_name = "Invalid Frame Name";
        }
      });
      // CHECK FRAME NAME ALREADY EXIST
      const enteredFrameName = values.frames[0].frame_name?.replaceAll(" ", "")?.toLowerCase();
      FrameData.map((ele) => {
        const existFrameName = ele.frame_name?.replaceAll(" ", "")?.toLowerCase();
        if (existFrameName ===  enteredFrameName) {
          errors.frame_name = "Frame name already exist!";
        }
      });
      return errors;
    },

    onSubmit: async (values, { resetForm }) => {
      setDisableCreateBtn(true);
      values.frames[0].layout_id = layoutId;
      values.frames[0].frame_index = FrameData.length + 1;
      let frameExist = false;
      FrameData.map((ele) => {
        if (ele.frame_name === values.frames[0].frame_name) {
          frameExist = true;
        }
      });

      if (!frameExist) {
        let res = await Post(`frame/create/frame`, values);
        if (res.statusCode === 200) {
          dispatch({
            type: "FRAME_LENGTH",
            item: res.data.length,
          });
          setLayout(res.data[0]?.layout_ref);
          dispatch({
            type: "GET_FRAME",
            item: res.data,
          });
          setAddframe(false);
          setShowadd(true);
          setDisableCreateBtn(false);
        }
      } else {
        setDisableCreateBtn(false);
        setAddframe(false);
        setShowadd(true);
      }
    },
  });

  const EditFrame = (
    e,
    layout_ref,
    frame_ref,
    frame_index,
    frame_name,
    frame_id
  ) => {
    if (loanCount == 0) {
      e.preventDefault();

      if (editframe.id === frame_id) {
        setEditframe({
          isEditActive: !editframe.isEditActive,
          id: frame_id,
        });
      } else {
        setEditframe({
          isEditActive: true,
          id: frame_id,
        });

        formikEdit.setValues({
          frames: [
            {
              frame_name: frame_name,
              frame_index: frame_index,
              layout_id: layout_ref,
              ref_no: frame_ref,
              frame_id: frame_id,
            },
          ],
        });
      }

      if (editframe.isEditActive) {
        formikEdit.setErrors({});
      }

      setEditIconClicked(true);
      setFrameID(frame_id);
      setLayoutId(layout_ref);
      setAddframe(false);
      setRef(frame_ref);
      setFrameIndex(frame_index);
      setFrameName(frame_name);
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const formikEdit = useFormik({
    initialValues: {
      frames: [
        {
          frame_name: "",
          frame_index: "",
          layout_id: "",
          ref_no: 0,
        },
      ],
    },

    validate: (values) => {
      const errors = {};
      values.frames.map((frame) => {
        if (frame.frame_name.trim().length === 0) {
          errors.frame_name = "Frame Name should not be empty";
        } else if (!/^[a-zA-Z0-9/-\s]*$/.test(frame.frame_name)) {
          errors.frame_name = "Invalid Frame Name";
        }
      });
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      values.frames[0].layout_id = layoutId;
      values.frames[0].layout_ref = layoutid;

      let frameAvailability = {
        isNameExist: false,
        isSameFrame: false,
      };

      for (let ele of FrameData) {
        if (
          ele.frame_name === values.frames[0].frame_name &&
          ele.frame_index !== values.frames[0].frame_index
        ) {
          frameAvailability.isNameExist = true;
          frameAvailability.isSameFrame = false;
          setEditframe({
            isEditActive: false,
            id: 0,
          });
          setEdt(false);
          setEditIconClicked(false);
        } else if (
          ele.frame_name === values.frames[0].frame_name &&
          ele.frame_index === values.frames[0].frame_index
        ) {
          frameAvailability.isNameExist = false;
          frameAvailability.isSameFrame = true;
          setEditframe({
            isEditActive: false,
            id: 0,
          });
          setEditIconClicked(false);
          resetForm();
        }
      }

      if (!frameAvailability.isNameExist && !frameAvailability.isSameFrame) {
        if (selectedFrame.frame_ref === values.frames[0].ref_no)
          dispatch({
            type: "SELECTED_FRAME",
            item: { ...values.frames[0], frame_ref: values.frames[0].ref_no },
          });

        let res = await Post(`frame/create/frame`, values);
        if (res.statusCode === 200) {
          dispatch({
            type: "FRAME_LENGTH",
            item: res.data.length,
          });
          setLayout(res.data[0]?.layout_ref);
          dispatch({
            type: "GET_FRAME",
            item: res.data,
          });
          setEditframe({
            isEditActive: false,
            id: 0,
          });
          setEdt(false);
          setEditIconClicked(false);
          // getFrames()
          // Reset the values
          resetForm();
        } else {
          setEditframe({
            isEditActive: false,
            id: 0,
          });
          setEditIconClicked(false);
          resetForm();
        }
      } else {
        frameAvailability = {
          isNameExist: false,
          isSameFrame: false,
        };
      }
    },
  });

  const deleteFrame = async (event, id) => {
    if (loanCount == 0) {
      if (editframe.isEditActive === true) {
        setEditframe({
          isEditActive: false,
          id: id,
        });
      }
      event.preventDefault();
      setShow(true);
      setFrameId(id);
    }
  };

  const handleDeleteFrame = async () => {
    setEditframe({ isEditActive: false, id: 0 });
    let res = await Delete(`frame/${frameid}`);
    if (res.statusCode === 200) {
      getFrames();
      setShow(false);
      dispatch({ type: "SELECTED_FRAME", item: "" });
      dispatch({ type: "SET_CONTROL", item: [] });
    } else {
      setShow(false);
    }
  };

  // ** Frame Selection**
  const handleFrameSelect = async (e, frame, index) => {
    if (e !== "") e.preventDefault();
    if (e !== "" && e.target.nodeName === "IMG") {
      return;
    } else {
      // if (frame.frame_name != currFrame) {
      // setEditframe({
      //   isEditActive: true,
      //   id: frame.frame_id,
      // });
      setEdt(false);
      setCurrFrame(frame.frame_name);
      dispatch({ type: "SELECTED_FRAME", item: frame });
      dispatch({ type: "SET_SAVE", item: false });
      dispatch({
        type: "SET_EDIT",
        item: { isEdit: false, currentTab: "profile" },
      });

      let resp = await Get(`controls/getByFrame/${frame.frame_id}`);
      if (resp.statusCode == 200) {
        sessionStorage.setItem("nextIndex", resp?.nextControlIndex);
        dispatch({
          type: "RESET_CONTROLS",
          item: [],
        });
        await updateRadioControllerItemName(resp.data, formGroupChildren, dispatch);
        dispatch({
          type: "SET_CONTROL",
          item: resp.data,
        });
      }
      // }
    }
  };

  useEffect(() => {
    if (selectedFrame?.frame_name === "Bank Lookup") {
      dispatch({
        type: "SET_CONTROLS_NOT_DRAGGABLE",
        payload: true,
      });
    } else {
      dispatch({
        type: "SET_CONTROLS_NOT_DRAGGABLE",
        payload: false,
      });
    }
  }, [selectedFrame]);

  //save reference for dragItem and dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const dragStart = (event, id) => {
    if (loanCount == 0) {
      dispatch({
        type: "SET_DRAGSTARTFROM",
        payload: "frame",
      });
      dragItem.current = id;
      {
        defaultLayout === false &&
          setTimeout(() => (event.target.style.display = "none"), 0);
      }
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const dragEnd = () => {
    dispatch({
      type: "PASS_OBJECT",
      item: {},
    });
    dispatch({type: 'SET_CONTROLDUM', item: []});
    dispatch({type: 'SET_DRAGSTARTFROM', payload: ''});
  };

  const dragEnter = (event, id) => {
    event.preventDefault();
    dragOverItem.current = id;
  };
  const dragOver = (event, id) => {
    event.preventDefault();
    dragOverItem.current = id;
    event.dataTransfer.dropEffect = "move";
  };

  //handle sort
  const handleSort = (e) => {
    if (loanCount == 0) {
      let frameDatasort = [...FrameData];
      {
        defaultLayout === false &&
          setTimeout(() => (e.target.style.display = "block"), 0);
      }

      frameDatasort.map((ele, i) => {
        if (ele.frame_index === dragOverItem.current) {
          dragOverItem.current = i;
        } else if (ele.frame_index === dragItem.current) {
          dragItem.current = i;
        }
      });

      if (defaultLayout === false) {
        const draggedItemContent = frameDatasort.splice(dragItem.current, 1)[0];
        frameDatasort.splice(dragOverItem.current, 0, draggedItemContent);
      }

      dragItem.current = null;
      dragOverItem.current = null;

      frameDatasort.map((ele, index) => {
        ele.frame_index = index + 1;
        ele.ref_no = ele.frame_ref;
      });
      dispatch({
        type: "GET_FRAME",
        item: frameDatasort,
      });

      postFrames({ frames: frameDatasort });
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  // Back Button
  const gotoLayout = () => {
    sessionStorage.clear();
    dispatch({
      type: "RESET_CONTROLS",
      item: [],
    });
    dispatch({
      type: "GET_FRAME",
      item: [],
    });
    dispatch({
      type: "SET_EDIT",
      item: { isEdit: false, currentTab: "profile" },
    });
    dispatch({ type: "SELECTED_FRAME", item: "" });
    let GlobalSettingsURL = `${process.env.REACT_APP_SAAS_URL}control-panel/dashboard/${id}/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imdva3Vsa2FuaXNoYmUrODE0QGdtYWlsLmNvbSIsInJvbGUiOiJ0ZW5hbnQiLCJpYXQiOjE2NzY5MTE5MTAsImV4cCI6MTY3NjkxNTUxMH0.pDldt3332gTmADMbh1GK7Xd8t6OTtHOdeZKopBksap4`;

    window.open(`${GlobalSettingsURL}`, "_self");
  };

  const handleSortFrameUp = (index) => {
    if (loanCount == 0) {
      if (index != 0) {
        let sortingData = [...FrameData];
        let draggedItem = sortingData.splice(index, 1)[0];
        sortingData.splice(index - 1, 0, draggedItem);
        dispatch({
          type: "GET_FRAME",
          item: sortingData,
        });
        sortingData.map((ele, i) => {
          ele.frame_index = i;
          ele.ref_no = ele.frame_ref;
        });
        postFrames({ frames: sortingData });
      }
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  const handleSortFrameDown = (index) => {
    if (loanCount == 0) {
      if (index != FrameData.length - 1) {
        let sortingData = [...FrameData];
        let draggedItem = sortingData.splice(index, 1)[0];
        sortingData.splice(index + 1, 0, draggedItem);
        dispatch({
          type: "GET_FRAME",
          item: sortingData,
        });
        sortingData.map((ele, i) => {
          ele.frame_index = i;
          ele.ref_no = ele.frame_ref;
        });
        postFrames({ frames: sortingData });
      }
    } else {
      dispatch({
        type: "SET_SHOWINFOICON",
        payload: true,
      });
    }
  };

  return (
    <>
      <div className="page_wrapper">
        <div className="sidebar_wrapper">
          <div>
            <div className="logo_brand">
              <img src={MainLogo} alt="" style={{ width: "150px" }} />
            </div>

            <div className="displayFlex layoutTitle AlignitemCenter">
              <div>
                {" "}
                <img src={layoutIcon} alt="" />
              </div>
              <div className="plr5 fontSize12"> {layoutName}</div>
            </div>

            <div className="displayFlex justifyContent_spaceBetween DesignFrameHeader">
              <div>
                <h3 className="headingText16 fontWeight_600">Frames</h3>
              </div>
              {defaultLayout === false && (
                <div>
                  {showadd ? (
                    <button
                      className="emptyButton"
                      onClick={addFrame}
                      data-cooltipz-dir="left"
                      aria-label="Add Frame"
                    >
                      <img src={AddBoxIcon} alt="" />
                    </button>
                  ) : (
                    <button
                      className="emptyButton"
                      alt=""
                      onClick={removeFrame}
                      data-cooltipz-dir="left"
                      aria-label="Remove Frame"
                    >
                      <img src={MinusIcon} alt="" />
                    </button>
                  )}
                </div>
              )}
            </div>
            {editframe.isEditActive && (
              <div className="DesignFrame">
                <h5 className="headingText14 fontWeight_500 mb_8">
                  Edit Frame
                </h5>
                <div className="displayFlex justifyContent_spaceBetween AlignitemCenter">
                  <div className="mr_8 width100per">
                    <input
                      className="smallInput"
                      type="text"
                      placeholder="Enter Frame Name"
                      name="frames[0].frame_name"
                      onChange={formikEdit.handleChange}
                      value={formikEdit.values.frames[0].frame_name}
                    />
                    {formikEdit.errors && (
                      <small className="errorText">
                        {formikEdit.errors.frame_name}
                      </small>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      onClick={formikEdit.handleSubmit}
                      className="emptyButton"
                      data-cooltipz-dir="left"
                      aria-label="Save"
                    >
                      <img
                        className="FrenchGreyFilter_Clr"
                        src={saveIcon}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {addframe ? (
              <div className="DesignFrame">
                <h5 className="headingText14 fontWeight_500 mb_8">Add Frame</h5>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb_12">
                    <label className="smalllabelText">Frame Name</label>
                    <input
                      type="text"
                      className="smallInput"
                      name="frames[0].frame_name"
                      placeholder="Enter Frame Name"
                      onChange={formik.handleChange}
                      value={formik.values.frame_name}
                    />

                    {formik.errors.frame_name && (
                      <p className="errorText">{formik.errors.frame_name}</p>
                    )}
                  </div>
                  <button
                    disabled={disableCreateBtn}
                    style={disableCreateBtn ? { opacity: 0.5 } : { opacity: 1 }}
                    type="submit"
                    className="smallMainButton"
                  >
                    Create
                  </button>
                </form>
              </div>
            ) : null}

            <div className="frameParent">
              {FrameData?.map((frames, index) => {
                return (
                  <div
                    draggable={defaultLayout ? "false" : "true"}
                    onDragStart={(event) =>
                      dragStart(event, frames.frame_index)
                    }
                    onDragEnter={(event) =>
                      dragEnter(event, frames.frame_index)
                    }
                    onDragEnd={(e) => handleSort(e)}
                    onDragOver={(event) => dragOver(event, frames.frame_index)}
                    style={
                      defaultLayout
                        ? { cursor: "auto" }
                        : frames?.active_flag
                        ? { cursor: "move" }
                        : { backgroundColor: "#dddddd", cursor: "move" }
                    }
                    className={
                      frames?.frame_name === selectedFrame.frame_name
                        ? "frameItem active"
                        : "frameItem"
                    }
                    onClick={(e) => {
                      handleFrameSelect(e, frames, index);
                    }}
                    key={index}
                  >
                    <form className="displayFlex justifyContent_spaceBetween AlignitemCenter">
                      <div style={{ width: "100%" }}>
                        <div className="displayFlex AlignitemCenter">
                          {defaultLayout === false && (
                            <div>
                              <img src={moveiconIcon} alt="" />
                            </div>
                          )}
                          <p
                            className="headingText14 fontWeight_400 plr5"
                            style={
                              frames?.active_flag
                                ? null
                                : { backgroundColor: "#dddddd", opacity: 0.2 }
                            }
                          >
                            {frames?.frame_name}
                          </p>
                        </div>
                      </div>
                      {defaultLayout && frames.isdefault ? (
                        <></>
                      ) : defaultLayout === false && frames.isdefault ? (
                        <></>
                      ) : (
                        <div className="displayFlex">
                          <div className="mr_6">
                            <button
                              data-cooltipz-dir="top"
                              aria-label={
                                editframe.isEditActive &&
                                editframe.id == frames.frame_id
                                  ? "Cancel"
                                  : "Edit"
                              }
                              className="emptyButton"
                              onClick={(e) =>
                                EditFrame(
                                  e,
                                  frames.layout_ref,
                                  frames.frame_ref,
                                  frames.frame_index,
                                  frames.frame_name,
                                  frames.frame_id
                                )
                              }
                            >
                              <img
                                className="FrenchGreyFilter_Clr"
                                src={
                                  editframe.isEditActive &&
                                  editframe.id == frames.frame_id
                                    ? closeIcon
                                    : FluentEditIcon
                                }
                                alt=""
                              />
                            </button>
                          </div>
                          <div>
                            <button
                              data-cooltipz-dir="top"
                              aria-label="Delete"
                              className="emptyButton"
                              alt=""
                              onClick={(event) =>
                                deleteFrame(event, frames.frame_id)
                              }
                            >
                              <img
                                className="FrenchGreyFilter_Clr"
                                src={FluentDeleteIcon}
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      )}
                      {!defaultLayout && (
                        <div
                          style={{ gap: "5px", marginLeft: "10px" }}
                          className="displayFlex justifyContent_spaceBetween AlignitemCenter"
                        >
                          <div
                            data-cooltipz-dir="left"
                            aria-label="Move Up"
                            onClick={() => handleSortFrameUp(index)}
                            style={{ cursor: "pointer" }}
                          >
                            &#8593;
                          </div>
                          <div
                            data-cooltipz-dir="left"
                            aria-label="Move Down"
                            onClick={() => handleSortFrameDown(index)}
                            style={{ cursor: "pointer" }}
                          >
                            &#8595;
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                );
              })}
            </div>
            {/* <div className="toggle-radio">
            <input type="radio" name="default" id="default_Option1" value="Option1" />
            <label for="default_Option1">Enable</label>

            <input type="radio" name="default" id="default_Option2" value="Option2" checked />
            <label for="default_Option2">Skip</label>

            <input type="radio" name="default" id="default_Option3" value="Option3" />
            <label for="default_Option3">Disable</label>
          </div> */}
          </div>
          <div className="textAlignRight">
            <p className="versionText">Workflow v1</p>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal show={show} onHide={handleClose} className="smallpopup">
          <Modal.Header closeButton>
            <Modal.Title>Delete Frame?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do You Really Want to Delete this Frame?</Modal.Body>
          <Modal.Footer>
            <div>
              <button className="smallMainButton" onClick={handleDeleteFrame}>
                Yes
              </button>
            </div>
            <div>
              <button className="smallMainButtonHover" onClick={handleClose}>
                No
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Delete Confirmation Modal End*/}

        <div className="content_wrapper">
          <header>
            <LayoutWorkflowMapping />
            <div>
              {isSaving ? (
                <button className="saving_spin ml_24">
                  <img src={SpinnerIcon} /> <span>{savingTxt}</span>
                </button>
              ) : null}
            </div>
            <div className="displayFlex AlignitemCenter gap12">
              {/* <div> */}

              {/* </div> */}
              {!Preview && FrameData.length > 0 && (
                <button className="MainButton mr_12" onClick={gotoPreview}>
                  Preview
                </button>
              )}
              {Preview && (
                <button className="MainButton mr_12" onClick={gotoForm}>
                  Back
                </button>
              )}
              {/* <button className="emptyButton" onClick={gotoLayout}>
                <img src={LogoutIcon} />
              </button> */}
            </div>
          </header>
          <div className="MainWrapper">
            <div id="FrameWrapper" className="FrameWrapper">
              <Frame />
            </div>
            <div className="field_setting">
              <div className="fieldHeader">
                {/* <h5>Setting</h5> */}
                <Tabs
                  defaultActiveKey="profile"
                  activeKey={currentTab}
                  onSelect={(key) => changeTabs(key)}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab
                    eventKey="design"
                    title={
                      <>
                        <img src={BrushIcon} alt="" />
                        <span> Design</span>
                      </>
                    }
                  >
                    <div>
                      {/* Edit Input type */}
                      {EditState.isEdit === true ? (
                        <EditAndDelete
                          id={HoverData.id}
                          prop={HoverData.props}
                          defaultControl={HoverData.defaultControl}
                          control_index={HoverData.control_index}
                        />
                      ) : (
                        <p className="noFields_text textAlignCenter ">
                          No Fields Selected ! <br /> Please Select a Field to
                          Edit...
                        </p>
                      )}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="profile"
                    title={
                      <>
                        <img src={ComponentIcon} alt="" />
                        <span>Components</span>
                      </>
                    }
                  >
                    {Object.keys(selectedFrame).length > 0 ? (
                      <div className="parentField">
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "text")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={InputFieldIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}> Text</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "date")}
                            onDragEnd={dragEnd}
                          >
                            <img src={DateIcon} alt="" draggable={"false"} />
                            <span draggable={"false"}> Date</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "number")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={InputNumberIcon}
                              alt=""
                              draggable={"false"}
                            />

                            <span draggable={"false"}>Number</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "password")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={PasswordIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}> Password</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) =>
                              getInput(e, "numberstringphone")
                            }
                            onDragEnd={dragEnd}
                          >
                            <img src={PhoneIcon} alt="" draggable={"false"} />
                            <span draggable={"false"}>Phone</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "numberstringaadhar")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={InputNumberIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}>Aadhar</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "numberstringpan")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={InputNumberIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}>Pan</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "email")}
                            onDragEnd={dragEnd}
                          >
                            <img src={EmailIcon} alt="" draggable={"false"} />
                            <span draggable={"false"}>Email</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "checkbox")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={CheckboxIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}>Check Box</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "radio")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={RadioCheckedIcon}
                              alt=""
                              draggable={"false"}
                            />

                            <span draggable={"false"}>Radio Button</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "textarea")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={TextareaIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}> Text Area</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "dropdown")}
                            onDragEnd={dragEnd}
                          >
                            <img
                              src={DropdownIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}>Dropdown</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout || controlsNotDraggable}
                            style={
                              defaultLayout || controlsNotDraggable
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout || controlsNotDraggable ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "paragraph")}
                            onDragEnd={dragEnd}
                          >
                            <img src={StringIcon} alt="" draggable={"false"} />
                            <span draggable={"false"}>Paragraph</span>
                          </button>
                        </div>

                        {/* <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "fileUpload")}
                          >
                            <img
                              src={FileuploadIcon}
                              alt=""
                              draggable={"false"}
                            />
                            <span draggable={"false"}>File Upload</span>
                          </button>
                        </div> */}
                        {/* <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable={defaultLayout ? "false" : "true"}
                            onDragStart={(e) => getInput(e, "sign")}
                          >
                            <img src={SignIcon} alt="" draggable={"false"} />
                            <span draggable={"false"}>Signature</span>
                          </button>
                        </div> */}
                      </div>
                    ) : (
                      <div className="parentField">
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "input")}
                          >
                            <img
                              src={InputFieldIcon}
                              alt=""
                              draggable="false"
                            />
                            <span> Name</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "date")}
                          >
                            <img src={DateIcon} alt="" draggable="false" />
                            <span> Date</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "number")}
                          >
                            <img
                              src={InputNumberIcon}
                              alt=""
                              draggable="false"
                            />

                            <span>Number</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "password")}
                          >
                            <img src={PasswordIcon} alt="" draggable="false" />
                            <span> Password</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) =>
                              getInput(e, "numberstringphone")
                            }
                          >
                            <img src={PhoneIcon} alt="" draggable="false" />
                            <span>Phone</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "numberstringaadhar")}
                          >
                            <img
                              src={InputNumberIcon}
                              alt=""
                              draggable="false"
                            />
                            <span>Aadhar</span>
                          </button>
                        </div>

                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "numberstringpan")}
                          >
                            <img
                              src={InputNumberIcon}
                              alt=""
                              draggable="false"
                            />
                            <span>Pan</span>
                          </button>
                        </div>









                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "email")}
                          >
                            <img src={EmailIcon} alt="" draggable="false" />
                            <span>Email </span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "checkbox")}
                          >
                            <img src={CheckboxIcon} alt="" draggable="false" />
                            <span>Check Box</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "radio")}
                          >
                            <img
                              src={RadioCheckedIcon}
                              alt=""
                              draggable="false"
                            />

                            <span>Radio Button</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "textarea")}
                          >
                            <img src={TextareaIcon} alt="" draggable="false" />
                            <span> Text Area</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "dropdown")}
                          >
                            <img src={DropdownIcon} alt="" draggable="false" />
                            <span>Dropdown</span>
                          </button>
                        </div>
                        <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "paragraph")}
                          >
                            <img src={StringIcon} alt="" draggable="false" />
                            <span>Paragraph</span>
                          </button>
                        </div>
                        {/* <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "fileUpload")}
                          >
                            <img
                              src={FileuploadIcon}
                              alt=""
                              draggable="false"
                            />
                            <span>File Upload</span>
                          </button>
                        </div> */}
                        {/* <div className="field_item">
                          <button
                            disabled={defaultLayout}
                            style={
                              defaultLayout
                                ? { cursor: "auto" }
                                : { cursor: "move" }
                            }
                            draggable="false"
                            onDragStart={(e) => getInput(e, "sign")}
                          >
                            <img src={SignIcon} alt="" draggable="false" />
                            <span>Signature</span>
                          </button>
                        </div> */}
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer
          position="top-center"
          autoClose={1800}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        /> *** */}
      </div>
    </>
  );
};

export default Controls;
