const initialValues = (name, id, fId, fgChild, groupBy) => {
  switch (name) {
    case "text":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        type: "text",
        required: true,
        minLength: 3,
        maxLength: 50,
        placeholder: "Enter the text",
        pattern: "",
        name,
        readonly: false,
      };
    case "checkbox":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        type: "checkbox",
        name,
        required: true,
        items: [
          { id: 1, key: "Checkbox 1", value: "checkbox_1" },
          { id: 2, key: "Checkbox 2", value: "checkbox_2" },
        ],
        readonly: false,
        alignStyle: "vertical",
      };
    case "date":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "date",
        required: true,
        dateFormat: "MM-DD-YYYY",
        dateCondition: "min_18",
        readonly: false,
      };
    case "number":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "number",
        required: true,
        placeholder: "Enter the number",
        minValue: 1,
        maxValue: 1000000000000,
        isdecimal: false,
        minDecimalDigits: 0,
        maxDecimalDigits: 0,
        readonly: false,
      };
    case "password":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "password",
        required: true,
        minLength: 8,
        maxLength: 30,
        pattern:
          "/^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?+&])[A-Za-zd@$!%*?&+]{8,}$/",
        placeholder: "Enter the password",
        styleClass: "col-md-6 mb_24",
        readonly: false,
      };
    case "dropdown":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        required: true,
        items: [
          {
            id: 1,
            name: `dropdown_${id}`,
            key: "Dropdown 1",
            value: "dropdown_1",
          },
          {
            id: 2,
            name: `dropdown_${id}`,
            key: "Dropdown 2",
            value: "dropdown_2",
          }
        ],
        type: "dropdown",
        readonly: false,
      };
    case "numberstringphone":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "text",
        required: true,
        placeholder: "Enter Phone Number",
        pattern: "",
        minLength: 10,
        maxLength: 10,
        readonly: false,
      };
    case "numberstringaadhar":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "text",
        required: true,
        placeholder: "Enter Aadhar Number",
        pattern: "",
        minLength: 12,
        maxLength: 12,
        readonly: false,
      };
      case "numberstringpan":
        return {
          labelName: `Label Text`,
          // labelName: `Label Name ${id}`,
          name,
          type: "text",
          required: true,
          placeholder: "Enter Pan Number",
          pattern: "",
          minLength: 10,
          maxLength: 10,
          readonly: false,
        };
    case "radio":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "radio",
        required: true,
        readonly: false,
        alignStyle: "vertical",
        isFormGroup: false,
        items: [
          {
            id: 1,
            name: fgChild ? `f${fId}_radio_g${groupBy}_${id}` : `f${fId}_radio_${id}`,
            key: "Radio 1",
            value: "radio_1",
            idFor: fgChild ? `f${fId}_radio_g${groupBy}_i1_${id}` : `f${fId}_radio_i1_${id}`,
          },
          {
            id: 2,
            name: fgChild ? `f${fId}_radio_g${groupBy}_${id}` : `f${fId}_radio_${id}`,
            key: "Radio 2",
            value: "radio_2",
            idFor: fgChild ? `f${fId}_radio_g${groupBy}_i2_${id}` : `f${fId}_radio_i2_${id}`,
          },
        ],
      };
    case "email":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "text",
        pattern: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i",
        required: true,
        placeholder: "Enter your Email",
        readonly: false,
      };
    case "textarea":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "textarea",
        required: true,
        minLength: 5,
        maxLength: 500,
        placeholder: "Enter the text ...",
        cols: 50,
        rows: 2,
        readonly: false,
      };
    case "fileUpload":
      return {
        labelName: `Label Text`,
        // labelName: `Label Name ${id}`,
        name,
        type: "file",
        required: true,
        maxLength: 5,
        placeholder: "Please Upload a file",
        multiple: "multiple",
        accept: "",
        fileFormat: {
          jpeg: true,
          jpg: true,
          pdf: true,
          png: true,
          xlsx: true,
          csv: true,
          zip: true,
        },
      };
    case "paragraph":
      return {
        name,
        type: "text",
        paragraph: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
        fontSize: 16,
        // maxLength: 350,
        // placeholder: 'Enter the paragraph ...',
      };
    case "sign":
      return {
        sign: "",
      };
  }
};

export default initialValues;
